import Domain from 'helpers/Domain';
import SignUpComponent from 'aa/vue/components/SignUp';
import Step from 'models/steps/Step';
import { SKIP_EXPLAINER_STEPS, SWITCH_PLAN_ENABLED } from 'aa/helpers/featureConstants';

export default class SignUp extends Step {
    path = '/account/signup/createaccount';
    name = 'SIGNUP';
    component = SignUpComponent;
    requireAuth = false;
    requireNonAuth = true;
    allowGhost = Domain.isDomestic();
    countStep = true;
    props = true;

    checkFlowRedirect(from, to) {
        return Domain.isDomestic()
            ? this.usCheckFlowRedirect(from)
            : this.intlCheckFlowRedirect(from, to);
    }

    usCheckFlowRedirect(from) {
        const { name } = from;

        if (
            this._store.getters.featureIsActive(SWITCH_PLAN_ENABLED) &&
            !this._store.getters['flow/skipPlan'] &&
            name !== 'MVPD'
        ) {
            if (!this._store.getters['plan/selectedPlan']) {
                if (this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS)) {
                    return { name: 'PLAN' };
                } else {
                    return { name: 'plan_explainer' };
                }
            }
        }
        return null;
    }

    intlCheckFlowRedirect(from, to) {
        // START A/B TEST PLOCTOPLUS-1391 : Skip Explainer Steps
        if (this._store.getters['variant/isIntlRemoveExplainerSteps']) {
            return null;
        }
        // END A/B TEST PLOCTOPLUS-1391 : Skip Explainer Steps
        const { name } = from;
        const { params, query } = to;
        const isSkipExp = this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS);
        const isDirectFlow = query?.redirectUrl && query?.redirectUrl.includes('/account/direct/');

        return isDirectFlow ||
            name === 'sign_up_explainer' ||
            name === 'MVPD' ||
            this._store.getters['flow/isGift'] ||
            params.fromPartnerConfirmation === true || // TODO: PPLUSINTL-12284: Change this value when component is refactored
            isSkipExp
            ? null
            : { name: 'sign_up_explainer' };
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);
        args.pageTitle = 'Paramount+ Sign Up - CBS.com';
        args.pageName = this.path;

        let productParams = this._store.getters['getProductParamsForTracking'];
        let signUpParams = this._store.getters['getTrackingParamsForSignUp'];

        Object.assign(args, productParams, signUpParams);

        return args;
    }
}
