<template>
    <section :class="wrapperClass">
        <div class="aa-funnel-grid aa-funnel-grid--reseller">
            <div>
                <h1 v-html="headerText" class="steps-header"></h1>
                <div v-html="subtitleText"></div>
                <div>&nbsp;</div>
                <div v-html="legalText"></div>
                <div class="text-center cta-wrapper">
                    <ButtonCta
                        v-if="pageAttr.aarp_signin_cta"
                        :buttonConfig="ctaConfig"></ButtonCta>
                </div>
                <p v-html="faqText" class="text-center"></p>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import ButtonCta from 'atoms/ButtonCta';
    import { PICK_PLAN_URL } from 'aa/vue/constants/aaRoutes';
    import ButtonConfig from 'models/ButtonConfig';
    import Breakpoints from 'helpers/breakpoints';
    import { tracking } from 'services/Tracking';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import LoadingSpinner from 'atoms/LoadingSpinner';

    export default {
        name: 'Aarp',
        components: {
            ButtonCta,
        },

        data() {
            return {
                aarpUrl: '',
            };
        },

        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            ...mapState('flow', {
                flow: (state) => state.config,
            }),
            ...mapState('authSuite', {
                authSuiteLoaded: (state) => state.isLoaded,
                authorizationService: (state) => state.authSuiteServices.authorizationService,
                callbackUrl: (state) => state.callbackUrl,
            }),
            ...mapState('user', {
                userState: (state) => state,
            }),
            wrapperClass() {
                return ['padded-container', 'reseller-landing'];
            },
            ctaConfig() {
                return new ButtonConfig({
                    displayText: this.pageAttr.aarp_signin_cta,
                    event: this.aarpSelectProvider,
                    styleClass: 'button',
                });
            },
            headerText() {
                return this.pageAttr.aarp_header_copy;
            },
            subtitleText() {
                return this.pageAttr.aarp_body_copy;
            },
            legalText() {
                return this.pageAttr.aarp_legal_copy;
            },
            faqText() {
                return this.pageAttr.aarp_faq_copy;
            },
            loginWindowName() {
                return `${this.flow.type.charAt(0).toUpperCase() + this.flow.type.slice(1)} Login`;
            },
        },

        methods: {
            loginWindowFeatures() {
                if (window.innerWidth > Breakpoints().getMax('mobileLandscape')) {
                    let h = screen.height / 2;
                    let w = screen.width / 2;

                    return `width=${w}, height=${h}`;
                }

                return `width=auto, height=auto`;
            },
            aarpSelectProvider() {
                tracking.trackAction('trackPartnerActivationCtaButton', {
                    ctaText: this.ctaConfig?.displayText,
                });

                this.$store.dispatch('authSuite/selectProvider', {
                    providerCode: 'aarp',
                });

                let callbackUrl = this.callbackUrl;

                let authLocation = window.open(
                    this.aarpUrl,
                    this.loginWindowName,
                    this.loginWindowFeatures(),
                );

                this.authorizationService
                    .getAuthenticationUrl({
                        callbackUrl: callbackUrl,
                        mvpdCode: 'aarp',
                    })
                    .then((resp) => {
                        authLocation.location.href = resp.authenticationUrl;
                    });
            },
        },

        watch: {
            '$store.state.authSuite.isLoaded': {
                handler(newValue, oldValue) {
                    if (newValue) {
                        this.$store.dispatch('authSuite/bindLoginEventAuthorizationCallbacks', {
                            authorizedResourceGroup: 'paramountplus-discount',
                            successCallback: () => {
                                window.location.href = PICK_PLAN_URL + '?promo=aarp';
                            },
                            errorCallback: () => {
                                this.openNotification(
                                    new NotificationBarModel({
                                        success: false,
                                        message: this.$getLocale('aarp_error_banner'),
                                    }),
                                );
                            },
                        });
                    }
                },
                immediate: true,
            },
        },
    };
</script>
