<template>
    <div class="pick-a-plan monthly-annual monthly-annual-picker-grid">
        <steps-header />
        <h2 class="plan-sub-heading">{{ attributes.secondary_sub_heading }}</h2>
        <div class="plan-box-wrapper">
            <MonthlyAnnualPlanBox
                :planTitle="attributes.monthly_plan_title"
                :priceInfo="getMonthlyPlanPriceInfo()"
                :idx="monthlyPlan.idx"
                :plan="monthlyPlan"
                :button="getMonthlyButtonConfig"
                :extraClass="'monthly'"></MonthlyAnnualPlanBox>
            <MonthlyAnnualPlanBox
                :planTitle="attributes.annual_plan_title"
                :priceInfo="getAnnualPlanPriceInfo()"
                :additionalInfo="getAnnualPlanAdditionalInfo()"
                :idx="annualPlan.idx"
                :plan="annualPlan"
                :button="getAnnualButtonConfig"
                :extraClass="'annual'"></MonthlyAnnualPlanBox>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex';
    import ButtonCta from 'atoms/ButtonCta';
    import MonthlyAnnualPlanBox from 'aa/vue/components/MonthlyAnnualPlanBox';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import { tracking, ACTION_NAMES } from 'services/Tracking';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import PaymentService from 'aa/vue/services/PaymentService';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'MonthlyAnnualPicker',
        props: {},
        mixins: [pricePrettyPrint],
        data: function () {
            return {
                /* stores configuration for the annualButton */
                annualButtonConfig: {},
                /* stores configuration for the monthlySelectButton */
                monthlyButtonConfig: {},
                /* stores the pageAttributes of text for the montly annual page picker config
               Expected obj should be similar to:
              {
                "current_plan":"Current Plan",
                "annual_plan_price_savings":"SAVE 15%",
                "heading":"Pick Your Plan",
                "monthly_plan_submit_button":"SELECT",
                "annual_plan_footer":"({subscriptionPrice} billed annually)",
                "tags":["flow_upsell"],
                "monthly_plan_title":"Monthly",
                "annual_plan_price":"{subscriptionPrice} / {subscriptionPeriod}",
                "monthly_plan_price":"{subscriptionPrice} / {subscriptionPeriod}",
                "sub_heading":"Try {trialDuration} free.",
                "annual_plan_title":"Annual",
                "annual_plan_trial":"after trial",
                "pageURL":"/PPlusPlanSelection/",
                "monthly_plan_trial":"after trial",
                "secondary_sub_heading":"Cancel or change your plan at any time. Free trials are for new subscribers only.",
                "annual_plan_submit_button":"SELECT"
              }
            */
                attributes: {},
                /* for the currency Symbol, used for pretty printing prices */
                currencySymbol: '',
                /* currencySubunits used in how we round to display the annual price as if it were monthly */
                currencySubunits: 100,
                /* will store the monthly plan prices and plan details */
                monthlyPlan: {},
                /* will store the annual plan prices and plan details */
                annualPlan: {},
            };
        },
        components: {
            StepsHeader,
            ButtonCta,
            MonthlyAnnualPlanBox,
        },
        /**
         */
        methods: {
            // update store with selected plan type

            ...mapActions('plan', ['setSelectedPlan', 'getPlans', 'setPlanType']),
            ...mapState('plan', {
                planType: (state) => state.planType,
            }),
            ...mapMutations('plan', ['setPickerPlanSelected']),
            mapPlans(plans) {
                plans.forEach((plan, idx) => {
                    if (plan.planType == Product.TYPE_ANNUAL) {
                        this.annualPlan = plan;
                        this.annualPlan.idx = idx;
                    } else if (plan.planType == Product.TYPE_MONTHLY) {
                        this.monthlyPlan = plan;
                        this.monthlyPlan.idx = idx;
                    }
                });
            },
            mapAttributes(attrs) {
                this.attributes = attrs;
                const annualPrice = this.pricePrettyPrint(
                    parseFloat(this.annualPlan.rawPrice.replace(/,/g, '')),
                    this.currencySymbol,
                    this.annualPlan.currency,
                );
                let annualMonthPrice =
                    Math.floor(
                        (parseFloat(this.annualPlan.rawPrice.replace(/,/g, '')) / 12) *
                            Math.pow(10, Math.log10(this.currencySubunits)),
                    ) / Math.pow(10, Math.log10(this.currencySubunits));
                annualMonthPrice = this.pricePrettyPrint(
                    annualMonthPrice,
                    this.currencySymbol,
                    this.annualPlan.currency,
                );
                const monthlyPrice = this.pricePrettyPrint(
                    parseFloat(this.monthlyPlan.rawPrice.replace(/,/g, '')),
                    this.currencySymbol,
                    this.monthlyPlan.currency,
                );
                const month = this.monthlyPlan.priceUnit;

                const annualFooterReplacePairs = {
                    calculatedMonthlyPrice: annualMonthPrice,
                    subscriptionPrice: annualPrice,
                    subscriptionPeriod: month,
                };
                for (const [key, value] of Object.entries(annualFooterReplacePairs)) {
                    this.attributes.annual_plan_footer = this.attributes.annual_plan_footer.replace(
                        `{${key}}`,
                        value,
                    );
                }

                const annualReplacePairs = {
                    calculatedMonthlyPrice: annualMonthPrice, // not adopted
                    subscriptionPrice: annualMonthPrice, // See note below
                    subscriptionPeriod: month,
                };
                /* After initial development we had legal reasons to swap footer and annual positions, with this we need subscriptionPrice to be the calculated monthly */

                for (const [key, value] of Object.entries(annualReplacePairs)) {
                    this.attributes.annual_plan_price = this.attributes.annual_plan_price.replace(
                        `{${key}}`,
                        value,
                    );
                }

                const monthlyReplacePairs = {
                    subscriptionPrice: monthlyPrice,
                    subscriptionPeriod: month,
                };
                for (const [key, value] of Object.entries(monthlyReplacePairs)) {
                    this.attributes.monthly_plan_price = this.attributes.monthly_plan_price.replace(
                        `{${key}}`,
                        value,
                    );
                }
            },

            getMonthlyPlanPriceInfo() {
                let info = this.attributes.monthly_plan_price;

                if (this.monthlyPlan.trialUnit) {
                    info += ' ' + this.attributes.monthly_plan_trial;
                }

                return info;
            },

            getAnnualPlanAdditionalInfo() {
                let info = this.attributes.annual_plan_price;

                if (this.annualPlan.trialUnit) {
                    info += ' ' + this.attributes.annual_plan_trial;
                }

                return info;
            },

            getAnnualPlanPriceInfo() {
                return (
                    this.attributes.annual_plan_price_savings +
                    ' ' +
                    this.attributes.annual_plan_footer
                );
            },
        },
        created() {
            this.currencySymbol = this.$store.state.serverData.currencySymbol;
            this.currencySubunits = this.$store.state.serverData.currencySubunits;
            this.mapPlans(this.$store.state.plan.plans);
            this.mapAttributes(this.$store.state.serverData.monthlyAnnualAttributes);
        },
        computed: {
            getMonthlyButtonConfig: function () {
                this.monthlyButtonConfig.styleClass = 'button primary';
                this.monthlyButtonConfig.displayText = this.attributes.monthly_plan_submit_button;
                this.monthlyButtonConfig.event = () => {
                    let selectedPlanType = Product.TYPE_MONTHLY;

                    // update store with selected plan type
                    this.setPlanType({
                        planType: selectedPlanType,
                    });
                    this.setSelectedPlan(this.monthlyPlan);
                    this.setPickerPlanSelected();
                    tracking.trackAction(ACTION_NAMES.PICK_PLAN_SELECTION, {
                        pickPlanSelection: 1,
                        pickPlanSku: this.monthlyPlan.recurlyCode,
                        ctaText: this.monthlyButtonConfig.displayText,
                    });

                    // get products if the current plan type is
                    // different from the selected plan type
                    if (this.planType !== selectedPlanType) {
                        this.getPlans();
                    }

                    this.$router.push(this.$store.getters['flow/getNextStep']);
                };
                return this.monthlyButtonConfig;
            },
            getAnnualButtonConfig: function () {
                this.annualButtonConfig.styleClass = 'button primary';
                this.annualButtonConfig.displayText = this.attributes.annual_plan_submit_button;
                this.annualButtonConfig.event = () => {
                    let selectedPlanType = Product.TYPE_ANNUAL;
                    this.setPlanType({
                        planType: selectedPlanType,
                    });

                    this.setSelectedPlan(this.annualPlan);
                    this.setPickerPlanSelected();
                    tracking.trackAction(ACTION_NAMES.PICK_PLAN_SELECTION, {
                        pickPlanSelection: 1,
                        pickPlanSku: this.annualPlan.recurlyCode,
                        ctaText: this.annualButtonConfig.displayText,
                    });

                    // get products if the current plan type is
                    // different from the selected plan type
                    if (this.planType !== selectedPlanType) {
                        this.getPlans();
                    }

                    this.$router.push(this.$store.getters['flow/getNextStep']);
                };
                return this.annualButtonConfig;
            },
        },
        mounted() {
            PaymentService.setHttpService(this.$store.$http);
        },
    };
</script>
