<template>
    <div class="aa-bundle-plan-display padded-container">
        <BackgroundPicture :backgroundImageSet="backgroundImageSet"></BackgroundPicture>

        <div>
            <img class="logo-pplus-showtime" alt="Paramount+ / Showtime" />

            <h1 v-html="heading"></h1>
            <p v-html="description"></p>

            <div class="coupon" v-if="this.hasValidCoupon()">
                <CouponIcon></CouponIcon>
                <p>
                    <span v-html="couponAppliedCopy"></span>
                    <span v-html="couponPriceDiscountCopy"></span>
                </p>
            </div>

            <div class="ctas">
                <ButtonCta :buttonConfig="selectPlanCTAConfig" data-ci="cta-bundle"></ButtonCta>
                <ButtonCta :buttonConfig="continueCTAConfig"></ButtonCta>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import ButtonConfig from 'models/ButtonConfig';
    import Plan from 'aa/vue/models/Plan';
    import BackgroundPicture from 'aa/vue/atoms/BackgroundPicture';
    import CouponIcon from 'aa/vue/atoms/CouponIcon';
    import priceDiscountMixin from 'aa/vue/components/account/priceDiscountMixin';
    import { tracking } from 'services/Tracking';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'BundlePlanDisplay',

        components: {
            ButtonCta,
            BackgroundPicture,
            CouponIcon,
        },

        mixins: [priceDiscountMixin],

        data: () => ({}),

        props: {
            backgroundImageSet: {
                type: Object,
            },
            pageAttributes: {
                type: Object,
            },
            plan: {
                type: Plan,
                required: true,
            },
            heading: { type: String },
            description: { type: String },
        },
        mounted() {},
        computed: {
            couponAppliedCopy() {
                if (!this.plan.couponDiscount) {
                    return null;
                }

                return this.getCopy('aa_bundle_plan_coupon_applied', {
                    couponCode: this.plan.couponDiscount.code.toUpperCase(),
                });
            },
            couponPriceDiscountCopy() {
                if (!this.plan.couponDiscount?.isPriceDiscount) {
                    return null;
                }

                if (this.plan.hasForeverCoupon()) {
                    return this.getCopy('aa_bundle_plan_coupon_price_discount', {
                        priceDiscount:
                            this.$getLocale('currency') + this.plan.couponDiscount.priceDiscount,
                    });
                }

                let discountDuration = this.formattedDiscountDuration(
                    this.plan?.couponDiscount?.duration,
                );
                let priceDiscount = this.priceDiscountCopy(this.plan);

                return this.getCopy('aa_bundle_plan_coupon_price_discount_with_duration', {
                    priceDiscount: priceDiscount,
                    discountDuration: discountDuration,
                });
            },
            continueCTAConfig() {
                const config = new ButtonConfig({
                    styleClass: ['button', 'terciary'],
                    tabindex: 0,
                    displayText: this.pageAttributes.aa_bundle_plan_decline_cta,
                    event: () => {
                        this.$emit('continue');
                        this.trackBundleCTA(config);
                    },
                });

                return config;
            },
            selectPlanCTAConfig() {
                const config = new ButtonConfig({
                    styleClass: ['button'],
                    id: 'selectPlan',
                    displayText: this.pageAttributes.aa_bundle_plan_bundle_cta,
                    tabindex: 0,
                    event: () => {
                        this.$emit('selectPlan');
                        this.trackBundleCTA(config);
                    },
                });

                return config;
            },
        },
        methods: {
            hasValidCoupon() {
                return this.plan.hasValidCoupon();
            },
            formattedDiscountDuration(couponDiscountDuration) {
                let amount = couponDiscountDuration.amount;
                let unit = couponDiscountDuration.unit;

                if (amount !== 1) {
                    unit = unit + 's';
                }

                return amount + ' ' + unit;
            },
            trackBundleCTA(clickedCTA) {
                let trackSelectAction =
                    clickedCTA.id === 'selectPlan'
                        ? 'trackBundlePartnerUpsell'
                        : 'trackBundlePartnerDismiss';

                let trackingParams = {
                    ctaText: clickedCTA.displayText,
                    partnerBundle: Product.ADDON_PARTNER_BUNDLE,
                };

                Object.assign(trackingParams, this.$store.getters['getProductParamsForTracking']);

                tracking.trackAction(trackSelectAction, trackingParams);
            },
        },
    };
</script>
