import AarpComponent from 'aa/vue/components/Aarp';
import Step from 'models/steps/Step';

export default class Aarp extends Step {
    path = '/account/aarp/';
    name = 'AARP';
    component = AarpComponent;
    requireAuth = false;
    requireNonAuth = false;
    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);
        let partnerCode = 'aarp';

        args.pageType = 'svod_aarp_activate';
        args.pageUrl = window.location.href;
        args.authPartnerId = partnerCode;

        return args;
    }
}
