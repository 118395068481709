<template>
    <div :class="classes" class="steps-header" data-ci="MultiSubBoxDataHeader">
        <div class="steps-header__section">
            <template v-if="content">
                <span class="line"></span>
                <span>{{ content }}</span>
                <span class="line"></span>
            </template>
            <template v-else>
                <span class="line"></span>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MultiSubBoxDataHeader',
        props: {
            content: {
                type: String,
                default: '',
            },
            classes: {
                type: Array,
                default() {
                    return {};
                },
            },
        },
    };
</script>
