import MonthlyAnnualPickerComponent from 'aa/vue/components/MonthlyAnnualPicker';
import Step from 'models/steps/Step';
import { PLAN_PICKER_PATH } from 'aa/vue/constants/shared';
import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';

export default class MonthlyAnnualPicker extends Step {
    path = PLAN_PICKER_PATH;
    name = 'PLAN';
    component = MonthlyAnnualPickerComponent;
    requireAuth = false;
    requireNonAuth = false;

    async beforeEnter(to, from, next) {
        this.getPromo();
        return super.beforeEnter(to, from, next);
    }

    /**
     * @param from
     * @returns {Promise<[unknown, unknown]>}
     */
    checkAccess(from) {
        return Promise.all([super.checkAccess(from), this._store.dispatch('plan/getPlans')]);
    }

    checkFlowRedirect(from) {
        if (!this._store.state.serverData.annualPlanOption) {
            if (!this._store.state.user.isLoggedIn) {
                if (
                    this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS) ||
                    this._store.getters['flow/isGift']
                ) {
                    return { name: 'SIGNUP' };
                } else {
                    return { name: 'sign_up_explainer' };
                }
            } else {
                if (
                    this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS) ||
                    this._store.getters['flow/isGift']
                ) {
                    return { name: this.$store.getters['payment/paymentRoute'] };
                } else {
                    return { name: 'payment_explainer' };
                }
            }
        }

        return super.checkFlowRedirect(from);
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageName = this.path;
        args.pageType = 'svod_pickaplan';

        args.userType = this._store.state.user.svod.status;
        args.userRegId = this._store.state.user.regID;

        return args;
    }
}
