<template>
    <div class="plan-box-wrapper pick-a-plan-grid-item" :class="extraClass">
        <div class="plan-box">
            <h3 class="plan-title">{{ planTitle }}</h3>
            <div v-if="trialString" class="plan-coupon">
                {{ trialString }}
            </div>
            <div v-if="plan.couponString && !isTrialCoupon" class="plan-coupon">
                {{ couponString }}
            </div>
            <div class="plan-price">{{ priceInfo }}</div>
            <div class="plan-info" v-if="additionalInfo">{{ additionalInfo }}</div>
            <div class="label_button" :aria-label="button.displayText">
                <ButtonCta :buttonConfig="button"></ButtonCta>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import { CreditCardPaymentConfig } from 'aa/vue/FormConfig';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';

    export default {
        name: 'MonthlyAnnualPlanBox',
        props: {
            planTitle: String,
            priceInfo: String,
            additionalInfo: String || null,
            button: Object,
            extraClass: String,
            idx: Number,
            plan: {
                type: Object,
                default: () => ({
                    trialInterval: '',
                    trialUnit: '',
                    tier: '',
                }),
            },
        },
        components: {
            ButtonCta,
        },
        mixins: [couponsMixin],
        computed: {
            recurly() {
                return this.$store.state.payment.recurly;
            },
            couponString() {
                return `${this.$getLocale('discount')}: ${this.plan.couponString}`;
            },
            isTrialCoupon() {
                return (
                    this.coupon && this.coupon.discount && this.coupon.discount.type == 'free_trial'
                );
            },
            trialString() {
                if (this.isTrialCoupon) {
                    return this.couponString;
                }
                return this.plan.trialString;
            },
            promo() {
                return this.$store.state.promo;
            },
        },
        data: function () {
            return { coupon: '' };
        },
        methods: {
            onCouponSuccess(coupon) {
                if (this.recurly) {
                    const { currencySymbol, currencyCode } = this.recurly.Pricing.Subscription();

                    this.$store.dispatch('plan/updatePlanCouponString', {
                        idx: this.idx,
                        planType: this.plan.planType,
                        couponString: this.formatCouponString(
                            coupon,
                            currencySymbol,
                            currencyCode,
                            this.plan?.planType,
                        ),
                    });
                }
            },
            onCouponFailure(error) {
                // ignore
            },

            updateTrialCopyByCoupon() {
                if (typeof this.recurly === 'object') {
                    try {
                        this.recurly.configure(CreditCardPaymentConfig.recurlyConfig);

                        let { promo } = this.$store.state;

                        if (promo) {
                            // from coupons Mixin
                            this.setupRecurlyPricing(this.onCouponSuccess, this.onCouponFailure);
                            this.applyCoupon(this.plan.recurlyCode, promo);
                        }
                    } catch (e) {
                        // ignore
                    }
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                if (this.recurly) {
                    this.updateTrialCopyByCoupon();
                }
            });
        },
        watch: {
            recurly(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
            promo(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
        },
    };
</script>
