import CadenceComponent from 'aa/vue/components/multisubplanpicker/Cadence';
import Step from 'models/steps/Step';
import { addClassToSelector, removeClassFromSelector } from 'aa/helpers/styleAdjuster';
import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';

export default class Cadence extends Step {
    path = '/account/signup/cadence/';
    name = 'cadence';
    component = CadenceComponent;
    props = true;
    requireAuth = true;
    requireNonAuth = false;
    forceNoPageAttrsNeedUpdate = true;
    countStep = true;

    async beforeEnter(to, from, next) {
        addClassToSelector('#main-container', 'msp');
        this.getPromo(from);

        const { stepsHeader, stepsCounter } = this._store.getters['plan/getDefaultHeadings'];
        to.params.stepsHeader = stepsHeader;
        to.params.stepsCounter = stepsCounter;

        return super.beforeEnter(to, from, next);
    }

    beforeRouteLeave(to, from, next) {
        removeClassFromSelector('#main-container', 'msp');
    }

    /**
     * @param from
     * @returns {Promise<[unknown, unknown]>}
     */
    checkAccess(from) {
        return Promise.all([super.checkAccess(from), this._store.dispatch('plan/getPlans')]);
    }

    checkFlowRedirect(from) {
        if (this._store.getters['plan/skipCadence']) {
            return { name: this.$store.getters['payment/paymentRoute'] };
        }
        if (
            !this._store.state.serverData.annualPlanOption &&
            !this._store.state.serverData.multiSubPlanPicker
        ) {
            if (!this._store.state.user.isLoggedIn) {
                if (
                    this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS) ||
                    this._store.getters['flow/isGift']
                ) {
                    return { name: 'SIGNUP' };
                } else {
                    return { name: 'sign_up_explainer' };
                }
            } else {
                if (
                    this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS) ||
                    this._store.getters['flow/isGift']
                ) {
                    return { name: this.$store.getters['payment/paymentRoute'] };
                } else {
                    return { name: 'payment_explainer' };
                }
            }
        }

        return super.checkFlowRedirect(from);
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageName = window.location.pathname;
        args.pageType = 'svod_pick-plan-payment';

        args.userType = this._store.state.user.svod.status;
        args.userRegId = this._store.state.user.regID;

        return args;
    }
}
