<template>
    <div class="centering-track">
        <ul class="accepted-cards">
            <li v-for="(item, index) in supportedCards" :key="index" :class="item.cardName"></li>
        </ul>
    </div>
</template>

<script>
    import filter from 'lodash/filter';

    export default {
        name: 'CreditCardLogos',
        props: {
            cards: {
                /* expected combination of -
          {"cardName": "amex", "enabled": true},
          {"mastercard", "visa", "enabled": false}
        */
                type: Array,
            },
        },
        data: function () {
            return {
                supportedCards: [],
            };
        },
        created() {
            this.supportedCards = filter(this.cards, 'enabled');
        },
    };
</script>
