import InterstitialStep from 'aa/vue/models/steps/InterstitialStep';
import Domain from 'helpers/Domain';
import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';

export default class PlanExplainer extends InterstitialStep {
    requireAuth = false;
    requireNonAuth = false;

    path = '/account/signup/plan/';
    name = 'plan_explainer';
    nameForTracking = 'EXPLAINER_STEPS_2';
    props = {
        title: 'You’re just 3 quick steps away from streaming!',
        description: 'First, pick your plan.',
        messages: [
            'Choose from Limited Commercials or Commercial Free (which includes the ability to download shows and watch offline). ',
            'Select monthly or annual payment options. The annual option saves you over 15% off the monthly price! ',
            'Your first 7 days are free, and you can cancel or change your plan at any time.',
        ],
        // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
        stepName: this.name,
        // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
    };

    checkFlowRedirect(from) {
        if (this._store.getters['flow/isGift']) {
            return { name: 'PLAN' };
        }
        if (Domain.isInternational()) {
            if (this._store.getters['flow/isGift']) {
                return super.checkFlowRedirect(from);
            }
            // START A/B TEST PLOCTOPLUS-1391 : Skip Explainer Steps
            if (this._store.getters['variant/isIntlRemoveExplainerSteps']) {
                return { name: 'tier' };
            }
            // END A/B TEST PLOCTOPLUS-1391 : Skip Explainer Steps
            if (
                !this._store.state.serverData.annualPlanOption &&
                !this._store.state.serverData.multiSubPlanPicker
            ) {
                if (!this._store.state.user.isLoggedIn) {
                    if (this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS)) {
                        return { name: 'SIGNUP' };
                    } else {
                        return { name: 'sign_up_explainer' };
                    }
                } else {
                    return {
                        name: this._store.getters['payment/paymentRoute'],
                    };
                }
            }
        }

        return super.checkFlowRedirect(from);
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageTitle = 'Explainer Page 1';
        args.pageType = 'svod_pickaplan_explainer';

        if (
            this._store.state.serverData.enhancedTrackActivationSuccess &&
            this._store.getters['flow/isRendezvous']
        ) {
            args.activationDeviceFamily = this._store.state.serverData.flow.partner;
        }

        return args;
    }
}
