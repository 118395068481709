import Interstitial from 'aa/vue/components/Interstitial';
import Step from 'models/steps/Step';

export default class InterstitialStep extends Step {
    name = 'interstitial-steps';
    component = Interstitial;

    checkFlowRedirect(from) {
        if (this._store.getters['flow/isGift']) {
            return this._store.getters['flow/getNextStep'].path;
        } else {
            return super.checkFlowRedirect(from);
        }
    }
}
