import SignInNogginComponent from 'aa/vue/components/SignInNoggin';
import Step from 'models/steps/Step';
import { PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';

export default class SignInNoggin extends Step {
    path = '/account/signin/noggin/';
    name = 'SIGNIN_NOGGIN';
    component = SignInNogginComponent;
    requireAuth = false;
    requireNonAuth = true;
    hideSignInLink = true;

    /**
     * Overriding using same page tag as primary signin page to inherit page attributes
     * @returns {string}
     */
    getPageAttributesPageTag() {
        return 'page_signin';
    }

    getRoute() {
        const route = super.getRoute();
        const meta = {
            hideHeader: true,
        };

        if (route.meta && typeof route.meta === 'object') {
            Object.assign(route.meta, meta);
        } else {
            route.meta = meta;
        }

        return route;
    }

    getTrackingArgs(to) {
        const args = super.getTrackingArgs(to);

        return args;
    }
}
