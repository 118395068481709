import Step from 'models/steps/Step';
import SharedPaymentComponent from 'aa/vue/components/payment/SharedPaymentComponent';
import {
    handleProcessedPayment,
    handleProcessedPaymentError,
} from 'aa/vue/components/payment/signupUtil';
import UserStatus from 'aa/vue/models/UserStatus';

export default class DirectLinkFunnel extends Step {
    path = '/account/direct/:directLinkFlow';
    name = 'DIRECTLINK';
    requireAuth = true;
    requireNonAuth = false;
    countStep = true;
    props = {
        isEditPayment: false,
        waitingOverlayEnabled: true,
        handleProcessedPayment,
        handleProcessedPaymentError,
        isFromDirect: true,
    };

    constructor() {
        super();
        this.component = SharedPaymentComponent;
    }

    beforeEnter(to, from, next) {
        const errorTrackingPageType = 'winback_directLink_error';
        const primaryButton = {};
        let serverData;
        const serverDataElem = document.getElementById('app-config');
        try {
            serverData = JSON.parse(serverDataElem.innerHTML);
        } catch (e) {
            serverData = {};
        }

        if (serverData?.user.isExSubscriber || serverData?.user.isRegistered) {
            primaryButton.link = '/';
        }

        if (to.query && to.query.redirect_error) {
            next({
                name: 'GenericError',
                params: {
                    heading: 'oops_something_went_wrong',
                    subHeading: ['direct_link_general_error'],
                    errorTrackingPageType,
                    primaryButton,
                },
            });
            return;
        }
        if (to.params.directLinkFlow !== 'su' && to.params.directLinkFlow !== 'si') {
            next({
                name: 'GenericError',
                params: {
                    heading: 'oops_something_went_wrong',
                    subHeading: ['direct_link_general_error'],
                    errorTrackingPageType,
                },
            });
            return;
        }
        if (!to.query || !to.query.tier || !to.query.cadence) {
            next({
                name: 'GenericError',
                params: {
                    heading: 'oops_something_went_wrong',
                    subHeading: ['direct_link_general_error', 'dl_error_plan_unavailable'],
                    errorTrackingPageType,
                    primaryButton,
                },
            });
            return;
        }

        const plans = serverData?.plans || [];
        const plan = plans.find(
            (plan) => plan.planType === to.query.cadence && plan.planTier === to.query.tier,
        );

        if (!plan) {
            next({
                name: 'GenericError',
                params: {
                    heading: 'oops_something_went_wrong',
                    subHeading: ['direct_link_general_error', 'dl_error_plan_unavailable'],
                    errorTrackingPageType,
                    primaryButton,
                },
            });
            return;
        }

        const userStatus = this._store.getters['user/getUserPackageStatus'];

        if (userStatus === UserStatus.STATUS_CANCEL_SCHEDULED) {
            next({
                name: 'GenericError',
                params: {
                    heading: 'oops_something_went_wrong',
                    subHeading: ['direct_link_general_error', 'dl_error_active_cancel_stat'],
                    errorTrackingPageType,
                    primaryButton,
                },
            });
            return;
        }

        this._store.dispatch('plan/setPlanTier', to.query.tier);
        this._store.dispatch('plan/setPlanType', { planType: to.query.cadence });

        this.getPromo(from);
        next();
    }
}
