<!-- START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
<template>
    <div class="upgrade-annual-plan-tiles">
        <PlanTile
            v-for="(plan, idx) in plansList"
            :key="idx"
            :idx="idx"
            :plan="plan"
            :price="priceWithDetail(plan)"
            :isActive="active === idx"
            @planSelected="handleClick"></PlanTile>
    </div>
</template>

<script>
    import PlanTile from './PlanTile';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';
    export default {
        name: 'UpgradeAnnualTwoPlanTiles',
        data: function () {
            return {
                active: 0,
            };
        },
        components: {
            PlanTile,
        },
        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            ...mapGetters('plan', {
                monthlySelectedAndAnnualAvailable: 'monthlySelectedAndAnnualAvailable',
                annualSelectedAndMonthlyAvailable: 'annualSelectedAndMonthlyAvailable',
            }),
            plansList() {
                return [
                    this.annualSelectedAndMonthlyAvailable?.[0],
                    this.monthlySelectedAndAnnualAvailable?.[0],
                ];
            },
        },
        methods: {
            handleClick(idx) {
                this.active = idx;
                this.$emit('filterAndSelect', idx === 1);
            },
            priceWithDetail(plan) {
                if (plan?.planType === Product.TYPE_MONTHLY) {
                    return plan.price;
                }

                try {
                    const priceBreakDown =
                        this.pageAttr?.aa_payment_upgrade_annual_monthly_price_format.replace(
                            '%MONTHLY_PRICE_WITH_UNIT%',
                            this.monthlySelectedAndAnnualAvailable?.[0].annualPlanMonthlyPriceWithUnit(),
                        );
                    return plan.price + ' ' + priceBreakDown;
                } catch (e) {
                    return plan.price;
                }
            },
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
