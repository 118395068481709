import Feature from 'aa/helpers/featureConstants';
import Product from 'aa/vue/models/Product';

export default {
    computed: {
        currentSubscription() {
            let temp = this.$store.getters.getServerDataByKey('currentSubscription');
            if (temp && Object.entries(temp).length > 0) {
                return temp;
            }
            return null;
        },

        hasAddOns() {
            return Boolean(this.currentSubscription?.addons?.length);
        },

        hasAddOnsCancelPending() {
            if (this.hasAddOns) {
                for (let addons of this.currentSubscription.addons) {
                    if (addons.scheduledForRemoval) return true;
                }
            }

            return false;
        },

        hasShowtimeAddOn() {
            if (this.hasAddOns) {
                return this.currentSubscription.addons[0].internalAddonCode.indexOf('SHO') === 0;
            }

            return false;
        },
        isCurrentSubscriptionShowtimeSku() {
            let planCode = this.currentSubscription?.user_plan?.planCode;
            let showtimeSkuRegex = new RegExp('allaccess_(lcp|ad_free)_sho_(annual|monthly)');

            return showtimeSkuRegex.test(planCode);
        },
        hasUpcomingPlanChanges() {
            if (this.currentSubscription) {
                let pending_sub_found = this.currentSubscription?.pending_sub ? true : false;
                if (pending_sub_found) {
                    if (
                        this.currentSubscription.pending_sub?.planCode ==
                        this.currentSubscription.user_plan?.planCode
                    ) {
                        pending_sub_found = false;
                    }
                }
                return pending_sub_found;
            }
            return false;
        },
    },

    methods: {
        isEquivalentProduct(productA, productB) {
            if (
                !Feature.partnerBundleEnabled() &&
                productA.package_code === Product.PACKAGE_CODE.CF
            ) {
                return (
                    productA.package_code === productB.package_code &&
                    productA.plan_type === productB.plan_type
                );
            }

            return (
                productA.package_code === productB.package_code &&
                productA.plan_type === productB.plan_type &&
                productA.addOnCodes?.join('|') === productB.addOnCodes?.join('|')
            );
        },
    },
};
