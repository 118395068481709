<template>
    <div class="plan__legal">
        <div v-if="legal" class="plan-box__legal">
            <LearnMoreLegal
                :legal="legal"
                :planType="this.plan.planType"
                :stepClass="stepClass"></LearnMoreLegal>
        </div>
    </div>
</template>

<script>
    import MultiSubBoxData from 'aa/vue/components/multisubplanpicker/MultiSubBoxData';
    import LearnMoreLegal from 'aa/vue/components/multisubplanpicker/LearnMoreLegal';
    import { mapGetters } from 'vuex';

    export default {
        name: 'MultiSubBoxLegal',
        data: function () {
            return {
                isCadenceStep: this.$route.path.includes('/cadence'),
            };
        },
        props: {
            plan: {
                type: Object,
                default: {
                    planType: '',
                    plan: {
                        planTier: '',
                    },
                },
            },
            isCurrent: {
                type: Boolean,
                default: false,
            },
            isSwitchPlan: {
                type: Boolean,
            },
            isUpgrade: {
                type: Boolean,
            },
            sameBillingTimeframe: {
                type: Boolean,
            },
            stepClass: {
                type: String,
                default: 'cadence',
            },
        },
        components: {
            MultiSubBoxData,
            LearnMoreLegal,
        },
        mounted() {},
        created() {},
        computed: {
            ...mapGetters('plan', ['getLegals']),
            legal() {
                return this.getLegals?.find((legal) => {
                    return legal.tags.find((tag) =>
                        tag.includes(
                            `${this.isCadenceStep ? 'billing' : 'plan'}_${this.plan.plan.planTier}`,
                        ),
                    );
                });
            },
            isDowngradingOnTrial() {
                if (!this.isSwitchPlan) {
                    return false;
                }

                return (
                    this.$store.getters['plan/currentPlan']?.on_trial &&
                    this.isCadenceStep &&
                    !this.isUpgrade
                );
            },
        },
        methods: {
            updateLegalText(disclaimer) {
                return disclaimer
                    .replace(/<Full Price>/gi, this.plan.plan_price)
                    .replace(/<term link>/i, this.switchPlanTermsTagOne)
                    .replace(/<term link>/i, this.switchPlanTermsTagTwo);
            },
        },
        watch: {},
    };
</script>
