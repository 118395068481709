<template>
    <div class="plan-product" :class="{ 'with-promo': isValidPromo }">
        <div class="sub-page--main-text">{{ displayPlanType }}</div>

        <!--current plan flag-->
        <div class="sub-page--current-plan-flag" v-if="isCurrentPlan">
            <span>{{ content.get('ap_upgrade_plan_current_plan') }}</span>
        </div>

        <!-- Promo placement -->
        <div v-if="promoText" class="sub-page--coupon lower">
            <span>{{ promoText }}</span>
        </div>

        <!--Plan Price-->
        <div class="sub-page--price">
            <action-button v-if="isAnnualPlan && !isValidPromo && pillText">
                <span class="pill">{{ pillText }}</span>
            </action-button>
            <span class="sub-page--price-descriptor" v-if="isValidPromo">{{ standardPrice }}</span>
            <span :class="{ discounted: isValidPromo }" class="sub-page--todays-price">
                {{ formattedPrice }}
            </span>
        </div>

        <div class="sub-page--price" v-if="isValidPromo">
            <span class="sub-page--price-descriptor">{{ yourSpecialOffer }}</span>
            <span v-if="formattedPriceWithPromo" class="sub-page--todays-price">
                {{ formattedPriceWithPromo }}
            </span>
        </div>

        <!--Trial text-->
        <div
            class="sub-page--sentence plan-desc"
            v-if="isAccountOnTrial && isCurrentPlan && isMonthlyPlan">
            <span>{{ trialText }}</span>
        </div>

        <!--CF Plan-->
        <div class="sub-page--sentence plan-desc" v-if="isCFPlan(product.product_code)">
            {{ content.get('ap_upgrade_plan_cf_subtext') }}
        </div>

        <!--Disclaimers-->
        <div class="sub-page--sentence plan-desc" v-if="isSelected && !isCurrentPlan">
            <p v-html="disclaimer"></p>
        </div>
    </div>
</template>

<script>
    import { CreditCardPaymentConfig } from 'aa/vue/FormConfig';
    import currentSubscriptionMixin from 'aa/vue/components/account/currentSubscriptionMixin';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';
    import priceMixin from 'aa/vue/plugins/priceMixin';
    import { mapState } from 'vuex';
    import ActionButton from 'atoms/ActionButton';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'PlanDisplay',
        mixins: [currentSubscriptionMixin, couponsMixin, priceMixin],
        components: {
            ActionButton,
        },
        props: {
            content: {
                type: Object,
            },
            product: {
                type: Object,
            },
            recurlyCurrencySymbol: {
                type: Object,
            },
            userProduct: {
                type: Object,
            },
            isSelected: {
                type: Boolean,
            },
        },
        data: function () {
            return {
                isValidPromo: false,
                coupon: null,
                // should match discount.type in recurly responsePlanDisplay.vue
                allowedCouponTypes: ['percent'],
                promoText: '',
                formattedPrice: '',
                subtotal: '',
                formattedPriceWithPromo: '',
                subtotalWithPromo: '',
                currentPrice: '',
                subscriptionTermsTagOne: "<a href='https://www.pplus.legal/tou' target='_blank'>",
                subscriptionTermsTagTwo: '</a>',
            };
        },
        beforeCreate() {
            CreditCardPaymentConfig.recurlyConfig.publicKey =
                this.$store.getters.getServerDataByKey('recurlyPublicKey'); //this.$root.serverData.recurlyPublicKey
        },
        mounted() {
            this.notifyPromoValidity(false);
            this.setupPricing();
        },
        computed: {
            standardPrice() {
                return `${this.$getLocale('standard_price')}:`;
            },
            pillText() {
                return this.content.get(
                    'ap_next_billing_switch_plan_pill_cta',
                    this.$getLocale('save_over_15_percent'),
                );
            },
            yourSpecialOffer() {
                return `${this.$getLocale('your_special_offer')}:`;
            },
            recurly() {
                return this.$store.state.payment.recurly;
            },
            displayPlanType() {
                if (this.product.plan_type === this.planTypeMonthly) {
                    return this.content.get(
                        'monthly_plan_title',
                        this.$getLocale(this.planTypeMonthly),
                    );
                }
                return this.content.get('annual_plan_title', this.$getLocale(this.planTypeAnnual));
            },
            isAccountOnTrial() {
                return this.user ? this.user.svod.user_package.on_trial : false;
            },
            planTypeAnnual() {
                return Product.TYPE_ANNUAL;
            },
            planTypeMonthly() {
                return Product.TYPE_ANNUAL;
            },
            isCurrentPlan() {
                return this.product.plan_type === this.userProduct.plan_type;
            },
            isMonthlyPlan() {
                return this.product.plan_type === this.planTypeMonthly;
            },
            isAnnualPlan() {
                return this.product.plan_type === this.planTypeAnnual;
            },
            trialText() {
                return this.content
                    .get('ap_upgrade_plan_trial')
                    .replace('[BILLING_DATE]', this.currentSubscription.next_bill_date_str);
            },
            disclaimer() {
                if (this.isValidPromo) {
                    return this.getPromoDisclaimer();
                } else {
                    return this.getDisclaimer();
                }
            },
            ...mapState('AccountSwitchPlan', {
                promo: (state) => state.promo,
            }),
            ...mapState('payment', {
                recurly: (state) => state.recurly,
            }),
        },
        methods: {
            notifyPromoValidity(bool) {
                this.$emit('isValidPromo', {
                    productId: this.product.id,
                    isValid: bool,
                });
            },
            checkForPromo() {
                if (this.hasPromo()) {
                    this.processPromo();
                }
            },
            setupPricing() {
                if (this.recurly && !this.pricing) {
                    this.setupRecurlyPricing(
                        (coupon) => {
                            if (this.allowedCouponTypes.indexOf(coupon.discount.type) !== -1) {
                                if (!this.isCurrentPlan) {
                                    this.isValidPromo = true;
                                    this.coupon = coupon;
                                    this.promoText = `${this.$getLocale('coupon')}: ${this.formatCouponString(
                                        coupon,
                                        this.recurlyCurrencySymbol.currencySymbol,
                                        this.recurlyCurrencySymbol.currencyCode,
                                        this.product.plan_type,
                                    )}`;
                                }
                            } else {
                                this.coupon = null;
                                this.isValidPromo = false;
                            }
                        },
                        (err) => {
                            this.isValidPromo = false;
                        },
                    );
                    this.pricing
                        .plan(this.product.product_code)
                        .catch((e) => {
                            //
                        })
                        .done((price) => {
                            this.updateFormattedPrice(price);
                        });

                    this.checkForPromo();
                }
            },
            processPromo() {
                this.pricing
                    .plan(this.product.product_code)
                    .coupon(this.promo)
                    .catch((e) => {
                        //
                    })
                    .done((price) => {
                        if (this.isValidPromo) {
                            this.updateFormattedPriceWithPromo(price);
                        }
                    });
            },
            hasPromo() {
                return !this.isCurrentPlan && this.promo && this.promo.length !== 0;
            },
            isUpgrading() {
                return this.userProduct.tier < this.product.tier;
            },
            updateDisclaimer(disclaimer) {
                return disclaimer
                    .replace(/<Full Price>/gi, this.currentPrice)
                    .replace(/<term link>/i, this.subscriptionTermsTagOne)
                    .replace(/<term link>/i, this.subscriptionTermsTagTwo);
            },
            getDisclaimer() {
                let product = this.product;

                let disclaimerText = '';
                let forfeitTrial = this.content.get('ap_upgrade_plan_forfeit_trial');

                // upgrading
                if (this.isUpgrading()) {
                    disclaimerText = this.content.get('ap_plan_upgrade_disclaimer');
                } else {
                    // downgrading
                    disclaimerText = this.content.get('ap_plan_downgrade_disclaimer');
                }

                if (this.isAnnualPlan) {
                    // switching from monthly to annual
                    if (this.userProduct.plan_type === Product.TYPE_MONTHLY) {
                        disclaimerText = this.updateDisclaimer(
                            // this.$getLocale("switch_to_annual_billing") (original)
                            this.$getLocale('switch_to_annual_billing_with_links'),
                        );
                    } else if (this.userProduct.tier > product.tier) {
                        // annual downgrading
                        disclaimerText = this.content.get('ap_annual_plan_downgrade_disclaimer')
                            ? this.content.get('ap_annual_plan_downgrade_disclaimer')
                            : disclaimerText;
                    }
                }

                if (this.isAccountOnTrial) {
                    disclaimerText = forfeitTrial.concat(' ', disclaimerText);
                }

                // hydrate dynamic token in the page attributes
                disclaimerText = disclaimerText.replace('[PLAN_NAME]', product.title);
                disclaimerText = disclaimerText.replace('[PRICE]', this.formattedPrice);
                disclaimerText = disclaimerText.replace(
                    '[BILLING_DATE]',
                    this.currentSubscription.next_bill_date_str,
                );

                return disclaimerText;
            },
            getPromoDisclaimer() {
                let massagedCoupon = this.getMassagedCoupon();
                let disclaimer = this.updateDisclaimer(
                    this.$getLocale('switch_to_annual_plan_with_coupon_applied_with_links'),
                );

                disclaimer = disclaimer.replace(/\[PLAN_NAME\]/gi, massagedCoupon.planName);
                disclaimer = disclaimer.replace(
                    /\[OFFER_DURATION\]/gi,
                    massagedCoupon.offerDuration,
                );
                disclaimer = disclaimer.replace(/\[OFFER_PRICE\]/gi, massagedCoupon.offerPrice);
                disclaimer = disclaimer.replace(/\[PRICE\]/gi, massagedCoupon.price);
                return disclaimer;
            },

            getMassagedCoupon() {
                let term = this.getTerm();

                if (this.coupon.discount.type === 'percent') {
                    return {
                        planName: this.product.title,
                        offerDuration:
                            this.coupon.temporal_amount + '-' + this.coupon.temporal_unit,
                        offerPrice: `${this.pricePrettyPrint(
                            this.subtotal,
                            this.recurlyCurrencySymbol.currencySymbol,
                            this.recurlyCurrencySymbol.currencyCode,
                        )}/${term}`,
                        price: this.formattedPrice,
                    };
                }
            },
            getTerm() {
                return this.isMonthlyPlan
                    ? this.content.get('ap_monthly', this.$getLocale('month'))
                    : this.content.get('ap_year', this.$getLocale('year'));
            },
            isLCPlan(productCode) {
                return productCode.substring(0, 5) === 'AA_LC';
            },
            isCFPlan(productCode) {
                return productCode.substring(0, 5) === 'AA_CF';
            },
            updateFormattedPrice(pricing) {
                const { currency, now } = pricing;
                const term = this.getTerm();

                this.subtotal = now.subtotal;

                this.currentPrice = this.pricePrettyPrint(
                    this.subtotal,
                    currency.symbol,
                    currency.code,
                );

                this.formattedPrice = `${this.currentPrice}/${term}`;
            },
            updateFormattedPriceWithPromo(pricing) {
                const { currency, now } = pricing;
                const term = this.getTerm();

                this.subtotalWithPromo = now.subtotal;

                this.formattedPriceWithPromo = `${this.pricePrettyPrint(
                    this.subtotalWithPromo,
                    currency.symbol,
                    currency.code,
                )}/${term}`;
            },
        },

        watch: {
            recurly(newVal, oldVal) {
                if (newVal) {
                    this.setupPricing();
                }
            },
            isValidPromo(n, o) {
                this.notifyPromoValidity(n);
            },
        },
    };
</script>
