<template>
    <div id="payment-annual-upsell" v-show="showAnnualUpsell">
        <div class="cta">
            <div class="heading">
                <div class="heading__title">
                    {{ getTitleCopy }}
                </div>
                <a @click="togglePlanType" class="toggle-btn-label">
                    {{ headingCta }}
                </a>
            </div>
            <div class="disclaimer">
                {{ getDisclaimerCopy }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';
    import { ANNUAL_UPSELL_PAYMENT } from 'aa/helpers/featureConstants';
    import { tracking, ACTION_NAMES } from 'services/Tracking';

    export default {
        name: 'PaymentAnnualUpsell',
        props: {
            title: {
                default: '',
                type: String,
                required: true,
            },
            disclaimer: {
                default: '',
                type: String,
                required: true,
            },
            paymentMethod: {
                default: '',
                type: String,
                required: true,
            },
            planTitle: {
                default: '',
                type: String,
                required: true,
            },
        },
        data: function () {
            return {
                headingCta: this.$getLocale('upgrade_cta'),
            };
        },
        methods: {
            ...mapActions('plan', ['filterPlans', 'updateSelectedPlan']),

            togglePlanType: function (e) {
                this.$store
                    .dispatch('plan/filterPlans', {
                        planType: Product.TYPE_ANNUAL,
                    })
                    .then(() => {
                        this.updateSelectedPlan(this.monthlySelectedAndAnnualAvailable?.[0]);
                    });
                tracking.trackAction(ACTION_NAMES.UPGRADE_ANNUAL_PAYMENT_PAGE, {
                    ctaText: this.headingCta,
                    purchaseProductName: this.planTitle,
                    purchasePaymentMethod: this.paymentMethod,
                });
            },
        },
        computed: {
            ...mapGetters('plan', {
                monthlySelectedAndAnnualAvailable: 'monthlySelectedAndAnnualAvailable',
            }),
            ...mapGetters('flow', {
                flowType: 'type',
            }),
            ...mapGetters(['featureIsActive']),
            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            ...mapGetters({
                upgradeAnnualToggle: 'featureFlags/upgradeAnnualToggle',
                upgradeAnnualPlanTiles: 'featureFlags/upgradeAnnualPlanTiles',
            }),
            // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
            showAnnualUpsell() {
                return (
                    this.featureIsActive(ANNUAL_UPSELL_PAYMENT) &&
                    this.monthlySelectedAndAnnualAvailable?.length > 0 &&
                    // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
                    !this.upgradeAnnualToggle &&
                    !this.upgradeAnnualPlanTiles
                    // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
                );
            },
            getTitleCopy: function () {
                return this.title;
            },
            getDisclaimerCopy: function () {
                return this.disclaimer.replace(
                    '%ANNUAL_PLAN_MONTHLY_PRICE_WITH_UNIT%',
                    this.monthlySelectedAndAnnualAvailable?.[0]?.annualPlanMonthlyPriceWithUnit(),
                );
            },
        },
    };
</script>
