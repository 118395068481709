import { tracking } from 'services/Tracking';
import ShowsPickerWrapper from 'components/ShowsPicker/ShowsPickerWrapper';
import Step from 'models/steps/Step';

export default class ShowsPickerStep extends Step {
    path = '/account/shows/picker/';
    name = 'ShowsPicker';
    component = ShowsPickerWrapper;
    //requireAuth = true
    //requireNonAuth = false
    requireSub = true;

    // switch this on in Q1?
    adultOnly = false;

    async beforeEnter(to, from, next) {
        let self = this;
        this.postponeTrackStep = true;

        this.checkAccess(from)
            .then(async () => {
                sessionStorage.setItem('new_sub_onboarding', this._store.state.user?.regID);
                // Init showspicker ab testing variants
                // this is part of th framework for ab tests on the show picker
                //the dispatch call should be commented out for performace when no tests are running
                tracking.trackState(self.getTrackingArgs(to));
                this.enterRoute(to, from, next);
                this._store.dispatch('showspicker/setInSignupFlag', true);
                //});
            })
            .catch((e) => {
                if (e === 'RESTRICTED') {
                    next({ name: 'AgeRestriction', replace: true });
                } else {
                    next(e);
                }
            });
    }

    enterRoute(to, from, next) {
        super.enterRoute(to, from, next);
        CBS.Registry.user_auth = true;
        if (CBS.Registry.Features.braze_sdk === true)
            import('shared/braze_events/events').then(({ onFreeTrialStart }) => {
                onFreeTrialStart();
            });
    }

    getTrackingArgs(to) {
        let values = super.getTrackingArgs(to);

        values.optimizelyExp = this._store.getters['variant/getTrackingData']();

        return {
            pageType: 'svod_show-picker',
            ...values,
            ...this.profileData,
        };
    }
}
