export default function SentryForVue(oVue) {
    return import('shared/sentry.io-vue')
        .then((module) => {
            const overrides = {};

            if (typeof __SENTRY_IO_CONFIG_RELEASE__ == 'string') {
                //Halt override w/ process.env.npm_package_version (rolls back to default BUILD_NUMBER)
                //overrides.release = __SENTRY_IO_CONFIG_RELEASE__
            }

            module.SentryForVue(oVue, overrides);
        })
        .catch(() => {});
}
