<template>
    <PartnerBundleDisplay
        :plan="bundlePlan"
        :pageAttributes="pageAttributes"
        :backgroundImageSet="backgroundImageSet"
        :heading="heading"
        :description="description"
        @selectPlan="onSelectPlan"
        @continue="onContinue"></PartnerBundleDisplay>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import PartnerBundleDisplay from 'aa/vue/components/PartnerBundleDisplay';
    import priceDiscountMixin from 'aa/vue/components/account/priceDiscountMixin';
    import Product from 'aa/vue/models/Product';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';

    export default {
        name: 'BundlePlan',

        components: {
            PartnerBundleDisplay,
        },

        mixins: [priceDiscountMixin, couponsMixin],

        computed: {
            backgroundImageSet() {
                return {
                    landscape: this.pageAttributeToImageSource(
                        this.pageAttributes.aa_bundle_plan_background_landscape,
                    ),
                    portrait: this.pageAttributeToImageSource(
                        this.pageAttributes.aa_bundle_plan_background_portrait,
                    ),
                };
            },

            bundlePlan() {
                return this.plans.find((plan) => {
                    let addOnCode = Product.ADDON_PARTNER_BUNDLE;
                    return (
                        plan.basePackageMatches(this.getCurrentPlan) &&
                        plan.planType === this.getCurrentPlan.planType &&
                        plan.hasAddOnCode(addOnCode)
                    );
                });
            },

            heading() {
                let bundlePlanHeadingPageAttribute =
                    this.pageAttributes.aa_bundle_plan_heading || '';
                let replacePriceWithUnit = this.$getLocale('currency') + '' + this.bundlePlan.price;

                if (
                    this.priceBeforeDiscount(this.bundlePlan) &&
                    this.priceAfterDiscount(this.bundlePlan)
                ) {
                    replacePriceWithUnit =
                        '<s>' +
                        this.priceBeforeDiscount(this.bundlePlan) +
                        '</s>' +
                        this.priceAfterDiscount(this.bundlePlan);
                }

                return bundlePlanHeadingPageAttribute.replace(
                    '{priceWithUnit}',
                    replacePriceWithUnit,
                );
            },

            description() {
                let bundlePlanDescriptionPageAttribute =
                    this.pageAttributes.aa_bundle_plan_description || '';

                return bundlePlanDescriptionPageAttribute;
            },

            ...mapGetters('flow', ['getNextStep']),

            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),

            ...mapState('plan', ['plans']),

            ...mapGetters('plan', ['getCurrentPlan']),
        },

        methods: {
            onSelectPlan() {
                this.$store.dispatch('plan/updateSelectedPlan', this.bundlePlan);
                this.$router.push(this.getNextStep);
            },

            onContinue() {
                // if no previous plan selected, navigation guards handle next step
                this.$router.push(this.getNextStep);
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.updatePriceAndCouponCopy(this.bundlePlan, this.$store.state.promo);
            });
        },
    };
</script>
