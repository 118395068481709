import { SUBSCRIBER_FLAG, ACTION_NAMES, PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';
import { tracking } from 'services/Tracking';

export default {
    data: function () {
        return {
            trackBindSubscriberFlag: this.getTrackBindSubscriberFlag(),
        };
    },
    methods: {
        /**
         * Note: evaluate subscriber flag before bind to get status before subscriber
         * @param {*} user
         * @returns
         */
        getTrackBindSubscriberFlag() {
            if (this.$store.state.user?.isExSubscriber) {
                return SUBSCRIBER_FLAG.EX_SUBSCRIBER;
            }

            return SUBSCRIBER_FLAG.NEW_SUBSCRIBER;
        },

        trackBindSuccess(params = {}) {
            try {
                const user = this.$store.state.user;

                const persistParams = Object.assign(
                    {
                        authPartnerId: params?.authPartnerId || window.utag_data?.authPartnerId,
                        pageType: 'svod_complete',
                        purchaseEventOrderComplete: '1',
                        purchaseProduct: user?.svod?.user_package?.product_code,
                        purchaseQuantity: '1',
                        subscriberFlag: this.trackBindSubscriberFlag,
                    },
                    user?.tags,
                );

                const trackParams = Object.assign(
                    {
                        actionName: ACTION_NAMES.BIND_SUCCESS,
                    },
                    persistParams,
                    params,
                );

                tracking.persistState(persistParams);
                tracking.trackState(trackParams);
            } catch (e) {}
        },
        trackBindAction(action) {
            try {
                const user = this.$store.state.user;

                const params = Object.assign(
                    {
                        authPartnerId: window.utag_data?.authPartnerId,
                        pageType: PAGE_TYPES.ACTIVATION_CONSENT,
                    },
                    user?.tags,
                );
                tracking.trackAction(action, params);
            } catch (e) {}
        },
    },
};
