<template>
    <div class="steps-header-message">
        <div
            class="steps-header-message__header"
            :class="headerClasses"
            :data-ci="headerClasses"
            v-html="header"></div>
        <div
            class="steps-header-message__subheader"
            :class="subheaderClasses"
            v-html="subheader"></div>
    </div>
</template>

<script>
    export default {
        name: 'steps-header-message',
        props: {
            header: {
                type: String,
                default: null,
            },
            subheader: {
                type: String,
                default: null,
            },
            headerClasses: {
                type: Array,
                default() {
                    return [];
                },
            },
            subheaderClasses: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        computed: {},
    };
</script>
