<template>
    <svg
        class="icon coupon"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="white" fill-opacity="0.4" />
        <path
            d="M14.8869 24.7276C15.4013 24.7276 15.8824 24.5296 16.2413 24.1697L24.1734 16.238C24.5142 15.898 24.7109 15.4412 24.7274 14.9523L24.7275 11.1816C24.7265 10.1296 23.8702 9.27364 22.8187 9.27349L19.0627 9.27148C18.5598 9.28757 18.1024 9.48471 17.7618 9.82701L9.83005 17.7587C9.47108 18.1173 9.27309 18.598 9.27265 19.1119C9.2725 19.6259 9.46979 20.1069 9.82891 20.4664L13.5334 24.1705C13.8924 24.5298 14.3733 24.7273 14.8869 24.7273L14.8869 24.7276ZM19.0755 10.0952L22.8182 10.0975C23.4164 10.0975 23.9033 10.5842 23.9039 11.1826V14.9389C23.8949 15.2043 23.7837 15.4636 23.591 15.6558L15.6586 23.5883C15.4549 23.7923 15.181 23.9046 14.8866 23.9046C14.5928 23.9046 14.3189 23.7925 14.1153 23.5889L10.4108 19.8847C10.2076 19.6811 10.0956 19.4072 10.0956 19.113C10.0957 18.8187 10.2082 18.5448 10.4116 18.3415L18.3441 10.409C18.5372 10.2155 18.7966 10.104 19.0754 10.0953L19.0755 10.0952ZM20.8451 14.9377C21.8279 14.9377 22.6282 14.1378 22.6283 13.1545C22.6277 12.1709 21.8278 11.371 20.8451 11.371H20.8449C19.8621 11.3711 19.0619 12.1711 19.0617 13.1542C19.0617 14.1376 19.8617 14.9376 20.8451 14.9377H20.8451ZM20.8448 11.7827L20.8449 12.1943C21.3739 12.1943 21.8045 12.6252 21.8048 13.1546C21.8047 13.6836 21.3739 14.1142 20.8449 14.1142C20.3157 14.1142 19.8851 13.6833 19.8849 13.1542C19.8851 12.6252 20.3158 12.1946 20.8449 12.1943L20.8448 11.7827Z"
            fill="white" />
    </svg>
</template>

<script>
    export default {
        name: 'CouponIcon',
    };
</script>
