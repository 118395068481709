import CompilingShows from 'components/ShowsPicker/CompilingShows';
import Step from 'models/steps/Step';
import { whenDomQueryLoaded } from 'helpers/whenDomElementLoaded';

export default class CompilingPageStep extends Step {
    path = '/shows/compiling/';
    name = 'CompilingPage';
    component = CompilingShows;
    requireSub = true;
    minimumSelectedCount = 3;

    getTrackingArgs(to) {
        let values = super.getTrackingArgs(to);

        return {
            pageType: 'compiling-page',
            ...values,
            ...this.profileData,
        };
    }

    async beforeEnter(to, from, next) {
        const store = this._store;
        this.checkAccess(from)
            .then(() => {
                // Validates there's shows selected (3 at least)

                if (
                    store.getters['showspicker/selectedShows'].length < this.minimumSelectedCount &&
                    !to.query.hasOwnProperty('no_redirect')
                ) {
                    next({
                        name: 'ShowsPicker',
                        replace: true,
                    });
                }
                this.enterRoute(to, from, next);
                whenDomQueryLoaded(
                    'BODY',
                    function () {
                        document.body.classList.add('on_compiling_show');
                    },
                    0,
                );
                this._store.dispatch('showspicker/setInSignupFlag');
            })
            .catch((e) => {
                if (e === 'RESTRICTED') {
                    next({ name: 'AgeRestriction', replace: true });
                }
            });
    }
}
