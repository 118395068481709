<template>
    <section class="padded-container" id="age-gate">
        <div class="aa-layout aa-layout--grid">
            <div class="aa-layout--grid__content age-gate-grid">
                <div class="age-gate-i-icon"></div>
                <div class="age-gate-sorry-message">
                    <p>{{ $getLocale('underage_signup_msg_header') }}</p>
                </div>
                <div class="age-gate-invalid-birthdate">
                    <p>{{ $getLocale('underage_signup_msg_body') }}</p>
                </div>
                <div class="age-gate-dismiss-btn">
                    <ButtonCta :buttonConfig="dismissButtonConfig"></ButtonCta>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import ButtonConfig from 'models/ButtonConfig';
    import { mapGetters } from 'vuex';
    import { AGE_GATE } from 'aa/helpers/featureConstants';
    import { tracking } from 'services/Tracking';

    export default {
        name: 'AgeGate',
        computed: {
            ...mapGetters({
                featureIsActive: 'featureFlags/isActive',
            }),
            dismissButtonConfig() {
                return new ButtonConfig({
                    styleClass: ['button'],
                    displayText: this.$getLocale('dismiss'),
                    event: () => {
                        tracking.trackAction('trackDismissInvalidBirthDateCta', {
                            pageType: 'svod_invalid-birthdate',
                            ctaText: this.$getLocale('dismiss'),
                        });
                        this.redirectToUpsell();
                    },
                });
            },
        },
        methods: {
            redirectToUpsell() {
                window.location.href = '/';
            },
        },
        created() {
            tracking.trackState({
                pageType: 'svod_invalid-birthdate',
            });
        },
        mounted() {
            if (!this.featureIsActive(AGE_GATE)) {
                // TODO: Redirect user if not enabled.
                this.redirectToUpsell();
            }
        },
        components: {
            ButtonCta,
        },
    };
</script>
