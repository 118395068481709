<!-- START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
<template>
    <div class="annual-toggle">
        <div>
            <SwitchToggle :config="toggleConfig" @toggle="toggle"></SwitchToggle>
        </div>
        <div class="subtitle-text">{{ getSubTitle }}</div>
    </div>
</template>

<script>
    import SwitchToggle from 'atoms/SwitchToggle';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'UpgradeAnnualToggle',
        components: {
            SwitchToggle,
        },
        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            toggleConfig() {
                return {
                    id: 'toggle',
                    value: this.getPlanType !== Product.TYPE_MONTHLY,
                    displayTexts: {
                        Off: this.getTitle,
                        On: this.getTitle,
                    },
                };
            },
            getPlanType() {
                return this.$store.getters['plan/getCurrentPlan']?.planType ?? '';
            },
            getSubTitle() {
                return this.pageAttr?.aa_payment_upgrage_to_annual_sub_title ?? '';
            },
            getTitle() {
                return this.pageAttr?.aa_payment_upgrage_to_annual_title ?? '';
            },
        },
        methods: {
            toggle(action) {
                this.$emit('filterAndSelect', action);
            },
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
