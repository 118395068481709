import { DateFormatter, getEndDate } from 'aa/helpers/DateFormatter';
import InterstitialStep from 'aa/vue/models/steps/InterstitialStep';
import { AA_PACKAGE_SWITCH } from 'helpers/featureConstants';
import { sprintf } from 'sprintf-js';
import Domain from 'helpers/Domain';
import UserStatus from 'aa/vue/models/UserStatus';
import { SKIP_EXPLAINER_STEPS } from 'aa/helpers/featureConstants';

export default class PaymentExplainer extends InterstitialStep {
    requireAuth = Domain.isDomestic();
    requireNonAuth = false;

    path = '/account/signup/plan';
    name = 'payment_explainer';
    nameForTracking = 'EXPLAINER_STEPS_3';
    props = {
        transformText: [
            (text) => {
                if (/%s/g.test(text)) {
                    return sprintf(text, this.formattedEndDate());
                } else {
                    return text;
                }
            },
        ],
        getEndTrialDate: (text, endDate) => {
            return /%s/g.test(text) ? sprintf(text, this.getEndTrialDate(endDate)) : text;
        },
    };

    async beforeEnter(to, from, next) {
        this.getPromo();
        return super.beforeEnter(to, from, next);
    }

    getEndTrialDate(endTrialDate) {
        let [amount, unit] = endTrialDate.split(' ');
        return this.getFormatEndDate(getEndDate(amount, unit));
    }

    formattedEndDate() {
        let currentPlan = this.plan,
            endDate = new Date(currentPlan.trialEndDate);

        return this.getFormatEndDate(endDate);
    }

    getFormatEndDate(endDate) {
        return DateFormatter.format(
            endDate,
            {
                month: 'long',
            },
            CBS.Registry.region.dateLocale,
        );
    }

    checkFlowRedirect(from) {
        const hasSkipExplainers = this._store.getters.featureIsActive(SKIP_EXPLAINER_STEPS);
        if (Domain.isDomestic()) {
            if (
                this._store.getters.featureIsActive(AA_PACKAGE_SWITCH) &&
                !this._store.getters['flow/skipPlan']
            ) {
                if (!this._store.getters['plan/selectedPlan']) {
                    return { name: hasSkipExplainers ? 'PICKAPLAN' : 'plan_explainer' };
                }
            }

            return null;
        } else {
            // TODO: check if all this is still actual for intl
            if (this._store.getters['flow/isGift']) {
                return super.checkFlowRedirect(from);
            }

            if (this._store.state.serverData.annualPlanOption) {
                if (!this._store.state.plan.pickerPlanSelected) {
                    return {
                        name: hasSkipExplainers ? 'PICKAPLAN' : 'plan_explainer',
                    };
                }
            }
            if (!this._store.state.user.isLoggedIn) {
                return {
                    name: hasSkipExplainers ? 'SIGNUP' : 'sign_up_explainer',
                };
            }
            return null;
        }
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        let productParams = this._store.getters['getProductParamsForTracking'];

        args.pageTitle = 'Explainer Page 3';
        args.pageType = 'svod_payment_explainer';
        args.userType = this._store.state.user.svod.status;
        args.userRegId = this._store.state.user.regID;
        args.userStatus = this._store.getters['user/trackingUserStatus'];

        Object.assign(args, productParams);

        return args;
    }
}
