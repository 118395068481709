import PickAPlan from 'aa/vue/components/PickAPlan';
import Plan from 'aa/vue/models/steps/Plan';

export default class CatanBundlePlanStep extends Plan {
    path = '/account/signup/pickplan/bundle/:partner/';
    name = 'CATAN_BUNDLE_PLAN';
    component = PickAPlan;
    requireAuth = false;
    requireNonAuth = false;
    countStep = true;

    async beforeEnter(to, from, next) {
        super.beforeEnter(to, from, next);
    }
}
