<template>
    <section>
        <div class="interstitial-steps-sections padded-container">
            <div class="interstitial-steps-wrapper">
                <img class="interstitial-steps-icon" v-if="isExplainerIcon" :src="icon" />
                <div class="interstitial-steps-title" v-html="title"></div>

                <div class="interstitial-steps-subtitle">
                    {{ subtitle }}
                </div>

                <div v-if="description" class="interstitial-steps-description">
                    {{ description }}
                </div>

                <template v-if="messages.length > 0">
                    <div
                        :class="[
                            'interstitial-details',
                            index === messages.length - 1 ? 'last' : '',
                        ]"
                        v-for="(item, index) in messages">
                        <div class="interstitial-detail-icon"></div>
                        <div class="interstitial-detail-text" v-html="item"></div>
                    </div>
                </template>

                <div class="interstitial_button">
                    <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
                </div>
            </div>
            <BackToRendezvousOptions
                :separator="false"
                v-if="!isDomestic"></BackToRendezvousOptions>
        </div>
    </section>
</template>

<script>
    import BackToRendezvousOptions from 'aa/vue/components/BackToRendezvousOptions';
    import ButtonCta from 'atoms/ButtonCta';
    import { CreditCardPaymentConfig } from 'aa/vue/FormConfig';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';
    import { mapState, mapGetters } from 'vuex';
    import { PLAN_PICKER_PATH } from 'aa/vue/constants/shared';
    import { pluralizeDuration } from 'aa/helpers/stringManipulation';
    import Domain from 'helpers/Domain';

    export default {
        step: null,

        name: 'interstitial-steps',

        data: function () {
            return {
                buttonConfig: {},
                processedMessages: [],
                monthlyPlan: {},
                annualPlan: {},
                couponTrials: [],
                processedCoupons: false,
                couponTrialDuration: false,
                couponTrialDurationRaw: false,
                isDomestic: Domain.isDomestic(),
            };
        },
        props: {
            transformText: {
                type: Array,
            },
            getEndTrialDate: {
                type: Function,
            },
            // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
            stepName: {
                type: String,
                required: false,
            },
            // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
        },

        components: {
            ButtonCta,
            BackToRendezvousOptions,
        },
        mixins: [couponsMixin],
        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            ...mapState('payment', ['recurly']),
            ...mapState(['promo']),
            ...mapState('plan', ['plans']),

            bundlePageAttrSuffix() {
                if (this.bundleShowtime) {
                    return '_showtime';
                }

                return '';
            },
            ...mapGetters(['bundleShowtime']),
            ...mapGetters('user', ['isNoTrialUser']),

            isExplainerIcon() {
                // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                if (this.isSkipABTestAndSignupExplainer || this.isSkipABTestAndPlanExplainer) {
                    return true;
                }
                // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                return this.pageAttr.explainer_icon;
            },

            // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
            ...mapGetters('variant', ['isIntlUpdatedExplainerSteps']),
            isSkipABTestAndSignupExplainer() {
                if (this.isIntlUpdatedExplainerSteps && this.stepName === 'sign_up_explainer') {
                    return true;
                }
                return false;
            },
            isSkipABTestAndPlanExplainer() {
                if (this.isIntlUpdatedExplainerSteps && this.stepName === 'plan_explainer') {
                    return true;
                }
                return false;
            },
            // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps

            title() {
                // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                if (this.isSkipABTestAndSignupExplainer) {
                    return this.$getLocale('title_signup_updatedexplainersteps');
                }
                if (this.isSkipABTestAndPlanExplainer) {
                    return this.$getLocale('title_plan_updatedexplainersteps');
                }
                // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                return this.pageAttr.explainer_title;
            },

            description() {
                if (CBS.Registry.brand.name !== 'CBS') return null;

                if (this.bundleShowtime) {
                    return this.pageAttr[`explainer_description${this.bundlePageAttrSuffix}`];
                }

                return this.pageAttr.explainer_description;
            },

            messages() {
                if (CBS.Registry.brand.name !== 'CBS' && Domain.isDomestic()) return [];
                // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                if (this.isSkipABTestAndSignupExplainer || this.isSkipABTestAndPlanExplainer) {
                    return [];
                }
                // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps

                let messages = [];
                for (let i = 1; i <= 3; i++) {
                    let message = this.rawExplainerLine(i);

                    if (this.trialDuration) {
                        messages.push(
                            this.getEndTrialDate
                                ? this.getEndTrialDate(message, this.trialDurationRaw)
                                : message,
                        );
                    } else {
                        messages.push(
                            this.transformText && this.transformText[i - 1]
                                ? this.transformText[i - 1](message)
                                : message,
                        );
                    }
                }

                return messages;
            },

            subtitle() {
                // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                if (this.isSkipABTestAndSignupExplainer) {
                    return this.$getLocale('subtitle_signup_updatedexplainersteps');
                }
                if (this.isSkipABTestAndPlanExplainer) {
                    return this.$getLocale('subtitle_plan_updatedexplainersteps');
                }
                // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                // show correct title
                if (this.bundleShowtime) {
                    // showtime specific
                    return this.pageAttr.explainer_subtitle_showtime;
                } else if (this.isGenericCampaign || this.isExSubscriber || this.hasPromoCode) {
                    // has generic toggle, coupon, or is ex-sub
                    return (
                        this.pageAttr.explainer_subtitle_generic ||
                        this.pageAttr.explainer_description
                    );
                } else {
                    // show default subtitle
                    return this.pageAttr.explainer_subtitle || this.pageAttr.explainer_description;
                }
            },
            icon() {
                // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                if (this.isSkipABTestAndSignupExplainer) {
                    return 'https://wwwimage-intl.pplusstatic.com/base/files/cbs_page_attribute/updatedexplainersteps_1.svg';
                }
                if (this.isSkipABTestAndPlanExplainer) {
                    return 'https://wwwimage-intl.pplusstatic.com/base/files/cbs_page_attribute/updatedexplainersteps_2.svg';
                }
                // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
                return this.$store.getters['cmsImageBaseURL'] + this.pageAttr.explainer_icon;
            },

            isBeforePlanPicker() {
                const steps = this.$store.state.flow.steps;
                const currentStepIndex = steps.findIndex((step) => {
                    return step.path === `${this.$route.path.replace(/\/$/, '')}/`;
                });
                const planStepIndex = steps.findIndex((step) => {
                    return step.path === PLAN_PICKER_PATH;
                });

                return currentStepIndex < planStepIndex;
            },
            trialDuration() {
                let planTrial = null;
                if (this.plans[0].trialUnit && this.plans[0].trialInterval) {
                    let trialUnit = this.plans[0].trialUnit;

                    trialUnit = pluralizeDuration(this.plans[0].trialInterval, trialUnit);
                    planTrial = `${this.plans[0].trialInterval} ${this.$getLocale(trialUnit).toLowerCase()}`;
                }

                return this.couponTrialDuration || planTrial;
            },

            trialDurationRaw() {
                let planTrial = null;
                if (this.plans[0].trialUnit && this.plans[0].trialInterval) {
                    let trialUnit = this.plans[0].trialUnit;

                    trialUnit = pluralizeDuration(this.plans[0].trialInterval, trialUnit);
                    planTrial = `${this.plans[0].trialInterval} ${trialUnit.toLowerCase()}`;
                }

                return this.couponTrialDurationRaw || planTrial;
            },
            explainerTitle() {
                const title = this.pageAttr.explainer_title || '';
                return title.replace('{trialDuration}', this.trialDuration);
            },
            explainerDescription() {
                const description = this.pageAttr.explainer_description || '';
                return description.replace('{trialDuration}', this.trialDuration);
            },

            ...mapState('user', {
                isExSubscriber: (state) => state.isExSubscriber,
            }),

            //TODO pass everything into button config constructor from Joe
            getButtonConfig: function () {
                const windowsClass =
                    navigator.userAgent.indexOf('Win') != -1 ? 'buttonWindows' : '';

                this.buttonConfig.styleClass = 'button primary ' + windowsClass;
                this.buttonConfig.displayText = this.$getLocale('_continue');
                this.buttonConfig.tabIndex = '0';
                this.buttonConfig.event = () => {
                    if (
                        this.$route.query.redirectUrl &&
                        this.$route.query.redirectUrl.includes('/account/direct/')
                    ) {
                        this.$router.push(
                            `${this.$store.getters['flow/getNextStep'].path}?redirectUrl=${encodeURIComponent(this.$route.query.redirectUrl)}`,
                        );
                    } else {
                        this.$router.push(this.$store.getters['flow/getNextStep']);
                    }
                };
                return this.buttonConfig;
            },

            hasPromoCode() {
                return this.$store.state.promo == null ? false : true;
            },

            isGenericCampaign() {
                return this.pageAttr.use_generic_campaign_explainer_set;
            },
        },
        methods: {
            rawExplainerLine(i) {
                const prefix = 'explainer_line_';
                const suffix = this.bundlePageAttrSuffix;
                const paWithPromo = this.pageAttr[prefix + i + '_promo' + suffix];

                if (paWithPromo && this.$store.state.promo) {
                    return paWithPromo;
                }

                if (this.isNoTrialUser) {
                    const paWithNoTrial = this.pageAttr[prefix + i + '_no_trial' + suffix];

                    if (paWithNoTrial) {
                        return paWithNoTrial;
                    }
                }

                return this.pageAttr[prefix + i + suffix];
            },

            onCouponSuccess(coupon) {
                if (this.recurly) {
                    const { currencySymbol, currencyCode } = this.recurly.Pricing.Subscription();

                    // Replace trialDuration with coupon trial duration IF:
                    // - After user has selected a plan, and that plan has a coupon trial
                    if (coupon.discount.type === 'free_trial') {
                        this.couponTrials.push(coupon.discount.trial);
                        const hasCouponTrialAndActivePlan =
                            !this.isBeforePlanPicker && this.couponTrials.length;

                        if (hasCouponTrialAndActivePlan) {
                            // Replace {trialDuration} with coupon trial duration
                            this.processedCoupons = true;
                            const amount = coupon.discount.trial.amount;
                            const unit = coupon.discount.trial.unit;

                            const pluralizedUnit = this.pluralizeDuration(amount, unit);
                            const localizedUnit = this.$getLocale(pluralizedUnit).toLowerCase();

                            this.couponTrialDurationRaw = `${amount} ${pluralizedUnit.toLowerCase()}`;
                            this.couponTrialDuration = `${amount} ${localizedUnit}`;
                        }
                    }
                }
            },
            onCouponFailure(error) {
                // ignore
            },
            updateTrialCopyByCoupon() {
                this.couponTrials = [];
                if (typeof this.recurly === 'object' && !this.processedCoupons) {
                    try {
                        this.recurly.configure(CreditCardPaymentConfig.recurlyConfig);

                        if (this.promo) {
                            // from coupons Mixin
                            this.setupRecurlyPricing(this.onCouponSuccess, this.onCouponFailure);

                            // If on an explainer step before the user has selected a plan, check all plans
                            // If on an explainer step after the user has selected a plan, just check the active (chosen) plan
                            if (this.isBeforePlanPicker) {
                                // Check all plans
                                this.plans.forEach((plan) => {
                                    this.applyCoupon(plan.recurlyCode, this.promo);
                                });
                            } else {
                                const activePlan = this.$store.getters['plan/getCurrentPlan'];

                                this.applyCoupon(activePlan.recurlyCode, this.promo);
                            }
                        }
                    } catch (e) {
                        // ignore
                    }
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                if (this.recurly) {
                    this.updateTrialCopyByCoupon();
                }
            });
        },
        watch: {
            recurly(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
            promo(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
            pageAttr(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
        },
    };
</script>
