import { ACCOUNT_XHR_BASE_PATH } from 'aa/vue/constants/aaRoutes';

let http;

export default {
    paths: {
        changeEmail: `${ACCOUNT_XHR_BASE_PATH}change-email/`,
        changePassword: `${ACCOUNT_XHR_BASE_PATH}change-password/`,
        requestChangePassword: `${ACCOUNT_XHR_BASE_PATH}request-change-password/`,
        redeemCoupon: `${ACCOUNT_XHR_BASE_PATH}redeem-coupon/`,
        removePromoCookie: `${ACCOUNT_XHR_BASE_PATH}remove-promo-cookie/`,
        changeScreenTime: `${ACCOUNT_XHR_BASE_PATH}change-screen-time/`,
    },

    setHttpService(httpService) {
        http = httpService;
    },

    changeEmail(reqData) {
        let path = this.paths.changeEmail;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        // TODO: handle this in HttpService
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    requestChangePassword() {
        return new Promise((resolve, reject) => {
            http.doGet(this.paths.requestChangePassword)
                .then((val) => {
                    resolve(val);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    changePassword(reqData) {
        let path = this.paths.changePassword;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        // TODO: handle this in HttpService
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    changeScreenTime(reqData) {
        let path = this.paths.changeScreenTime;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    redeemCoupon(reqData) {
        let path = this.paths.redeemCoupon;

        return new Promise((resolve, reject) => {
            http.doPost(path, reqData)
                .then((val) => {
                    if (val.success) {
                        // TODO: handle this in HttpService
                        resolve(val.data);
                    } else {
                        reject(val);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    removePromoCookie() {
        let path = this.paths.removePromoCookie;
        return new Promise((resolve, reject) => {
            http.doGet(path)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
