import { mapActions, mapGetters } from 'vuex';

import { CreditCardPaymentConfig } from 'aa/vue/FormConfig';
import { ButtonState } from 'models/ButtonConfig';
import { AA_DYNAMIC_AGREEMENT_COPY } from 'helpers/featureConstants';
import {
    MULTI_SUB_PLAN_PICKER_ENABLED,
    WAIVE_COOLING_RIGHTS_ENABLED,
} from 'aa/helpers/featureConstants';
import PaymentService from 'aa/vue/services/PaymentService';
import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
import { ACTION_NAMES, tracking } from 'services/Tracking';
import Domain from 'helpers/Domain';
import { DateFormatter, getEndDate, addCouponDiscountPeriod } from 'aa/helpers/DateFormatter';
import Product from 'aa/vue/models/Product';

export default {
    data() {
        return {
            recurlyWrappersLoaded: false,
            pricing: null,
            coupon: {},
            threeDSecureEnabled: false,
            internationalizedPrice: '',
            internationalizedPriceWithUnit: '',
            couponDisclaimerCopy: '',
            trialEndDateFormatted: '',
            appliedCouponCode: this.$store.state.promo,
            registrationCountry: false,
            submittedCouponCode: undefined,
            enhancedPaymentSummaryEnabled: false,
            nextNonZeroBillingPrice: '',
            waiveOfCoolingRightsEnabled: false,
            waiveOfCoolingChecked: false,
            waiveOfCoolingRighsCheckboxErrorMessage: '',
        };
    },
    computed: {
        plan() {
            if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                return this.$store.getters['plan/getSelectedPlan'];
            } else {
                return this.$store.getters['plan/getCurrentPlan'];
            }
        },
        getSummaryAgreementCopy() {
            return this.enhancedPaymentSummaryEnabled
                ? this.pageAttr.billing_summary_agreement_copy
                : this.pageAttr.aa_payment_agreement_copy;
        },
        couponCheckPending() {
            return Boolean(this.appliedCouponCode && !this?.$store?.state?.autoPromoChecked);
        },
        recurly() {
            return this.$store.state.payment.recurly;
        },
        needsWaiveOfCoolingRights() {
            return (
                this.enhancedPaymentSummaryEnabled &&
                this.waiveOfCoolingRightsEnabled &&
                Boolean(this.pageAttr?.['waive_of_cooling_rights']) &&
                !this.isEditPayment
            );
        },
        waiveOfCoolingRighsCheckboxConfigs() {
            return {
                type: 'checkbox',
                elementId: 'waiveCoolingRights',
                inputLabel: this.pageAttr['waive_of_cooling_rights'],
                wrapperClass:
                    'payment-layout__summary__waive-cooling-rights cbs-checkbox cbs-checkbox--waive-cooling-rights',
                styleClass: 'qt-waiveCoolingRights',
                classList:
                    'payment-layout__summary__waive-cooling-rights__checkbox cbs-checkbox-input',
                value: false,
            };
        },
        buttonConfig() {
            if (!this.isEditPayment) {
                return {
                    type: 'button',
                    displayTexts: {
                        [ButtonState.Loading]: this.$getLocale('_processing'),
                        [ButtonState.Enabled]: this.enhancedPaymentSummaryEnabled
                            ? this.pageAttr.billing_summary_cta_form_submit
                            : this.pageAttr.cta_form_submit,
                    },
                    id: 'payment-submit',
                    styleClass: 'payment-layout__summary__submit button',
                    qaClass: 'qt-AAbtn',
                };
            } else {
                return {
                    displayText: this?.isFromPaymentSummary
                        ? this.pageAttr.update_cta
                        : this.$getLocale('save'),
                    id: 'payment-submit',
                    styleClass: [
                        'payment-layout__summary__submit button',
                        this?.editPaymentSuccess ? 'success' : '',
                    ],
                };
            }
        },
        submitButtonState() {
            if (
                typeof this.tab !== 'undefined' &&
                this.activeTab === this.tab.payPal &&
                !this.needsWaiveOfCoolingRights
            ) {
                return ButtonState.Enabled;
            }

            if (this.formIsProcessing) {
                return ButtonState.Loading;
            }

            if (this.needsWaiveOfCoolingRights) {
                if (
                    this.activeTab === this.tab.cc &&
                    (!this.formIsValid || !this.waiveOfCoolingChecked)
                ) {
                    return ButtonState.Disabled;
                }
                if (this.activeTab === this.tab.payPal && !this.waiveOfCoolingChecked) {
                    return ButtonState.Disabled;
                }
            } else if (!this.formIsValid) {
                return ButtonState.Disabled;
            }

            return ButtonState.Enabled;
        },
        bundlePageAttrSuffix() {
            if (this.bundleShowtime) {
                return '_showtime';
            }

            return '';
        },
        dataRecurlyFieldKeyMap() {
            let result = {};

            Object.keys(this.fieldConfigs).forEach((fieldKey) => {
                let fieldConfig = this.fieldConfigs[fieldKey];

                if (fieldConfig.dataRecurly) {
                    result[fieldConfig.dataRecurly] = fieldKey;
                }
            });

            return result;
        },
        pageAttr() {
            let pageAttributes;

            if (this.isEditPayment) {
                pageAttributes = this.$store.state.payment.pageAttributes;
                if (this.isFromPaymentSummary) {
                    pageAttributes.update_cta =
                        this.$store.state.AccountSwitchPlan.pageAttributes.update_cta || '';
                }
            } else if (
                this.$router.currentRoute.name === 'AccountSummary' ||
                this.$router.currentRoute.name === 'directAccountSummary'
            ) {
                pageAttributes = this.$store.state.AccountSwitchPlan.pageAttributes;
            } else {
                pageAttributes = this.$store.state.pageAttributes.attributes;
            }

            if (this.bundleShowtime) {
                return Object.assign({}, pageAttributes, {
                    aa_payment_paypal_success_copy:
                        pageAttributes[
                            `aa_payment_paypal_success_copy${this.bundlePageAttrSuffix}`
                        ],
                    aa_payment_agreement_copy:
                        pageAttributes[`aa_payment_agreement_copy${this.bundlePageAttrSuffix}`],
                    aa_payment_agreement_copy_monthly:
                        pageAttributes[
                            `aa_payment_agreement_copy_monthly${this.bundlePageAttrSuffix}`
                        ],
                    aa_payment_agreement_copy_annual:
                        pageAttributes[
                            `aa_payment_agreement_copy_annual${this.bundlePageAttrSuffix}`
                        ],
                    cta_form_submit: pageAttributes[`cta_form_submit${this.bundlePageAttrSuffix}`],
                });
            }

            return pageAttributes;
        },

        ...mapGetters(['bundleShowtime', 'featureIsActive']),

        ...mapGetters('plan', ['isSwitchPlan']),

        // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
        ...mapGetters({
            upgradeAnnualToggle: 'featureFlags/upgradeAnnualToggle',
            upgradeAnnualPlanTiles: 'featureFlags/upgradeAnnualPlanTiles',
        }),
        //END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

        plan() {
            if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                return this.$store.getters['plan/getSelectedPlan'];
            } else {
                return this.$store.getters['plan/getCurrentPlan'];
            }
        },
    },
    methods: {
        ...mapActions('payment', [
            'retrieveRecurlyToken',
            'retrieveRecurlyPayPalToken',
            'processPayment',
            'processEditPayment',
        ]),
        /**
         * shared proxy to updateSubTotal() to alleviate duplicate code
         * TODO: consider refactor to other usages of updateSubTotal
         */
        formatOrderSummaryTotals() {
            const { currencySymbol, currencyCode } = this.recurly.Pricing.Subscription();
            this.updateSubTotal(currencySymbol, currencyCode);
            this.getNextNonZeroBillingPrice();
        },
        updateSubTotal(currencySymbol, currencyCode) {
            const subtotals = document.querySelectorAll('[data-recurly^="subtotal"]');

            subtotals.forEach((i) => {
                i.textContent = this.pricePrettyPrint(i.textContent, currencySymbol, currencyCode);
            });
        },
        displayCouponErrorMessage(message) {
            this.coupon = {
                errorMessage: this.$getLocale(message),
            };

            // clear any coupon disclaimer
            this.couponDisclaimerCopy = '';
        },
        getNextNonZeroBillingPrice() {
            let nextCostToUser = this.internationalizedPrice;
            let found = false;

            if (this.pricing?.price) {
                if (this.pricing.price.now?.subtotal) {
                    if (parseFloat(this.pricing.price.now.subtotal) > 0) {
                        nextCostToUser = this.pricing.price.now.subtotal;
                        found = true;
                    }
                }
                if (this.pricing.price.next?.subtotal && !found) {
                    if (parseFloat(this.pricing.price.next.subtotal) > 0) {
                        nextCostToUser = this.pricing.price.next.subtotal;
                        found = true;
                    }
                }
            }
            if (found) {
                nextCostToUser = this.pricePrettyPrint(
                    nextCostToUser,
                    this.pricing.currencySymbol,
                    this.pricing.currencyCode,
                );
            }
            this.nextNonZeroBillingPrice = nextCostToUser;
        },

        configureRecurly() {
            if (this.recurly) {
                const currentPlan = this.plan || {};
                this.recurly.configure({
                    ...CreditCardPaymentConfig.recurlyConfig,
                    currency: currentPlan.currency,
                    risk: {
                        threeDSecure: {
                            preflightDeviceDataCollector: this.threeDSecureEnabled ?? false,
                        },
                    },
                });

                !this.isEditPayment && this.setupPricingHandlers();
            } else if (this.isEditPayment) {
                this.openNotification(
                    new NotificationBarModel({
                        success: false,
                        message: this.$getLocale('something_went_wrong_please_try_again_later'),
                    }),
                );
            }
        },
        getAgreementCopy() {
            if (!this.plan) {
                return '';
            }
            if (this.featureDynamicAgreementCopy) {
                let copy = this.plan.isMonthly
                    ? this.pageAttr.aa_payment_agreement_copy_monthly
                    : this.pageAttr.aa_payment_agreement_copy_annual;

                const price = this.plan.price;
                const disclaimerCopy = copy.replace(/\[PRICE\]/gi, price);

                // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
                if (
                    (this.upgradeAnnualPlanTiles || this.upgradeAnnualToggle) &&
                    this.pageAttr?.aa_payment_additional_disclaimer_copy
                ) {
                    return (
                        disclaimerCopy + ' ' + this.pageAttr.aa_payment_additional_disclaimer_copy
                    );
                }
                // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

                return disclaimerCopy;
            } else {
                let agreement_copy = this.getSummaryAgreementCopy ?? '';

                if (agreement_copy.length !== 0) {
                    const { planType } = this.plan;
                    let localizedSubPeriod = '';
                    if (planType === Product.TYPE_MONTHLY) {
                        localizedSubPeriod = this.$getLocale('month');
                    } else if (planType === Product.TYPE_ANNUAL) {
                        localizedSubPeriod = this.$getLocale('year');
                    }

                    if (this.internationalizedPrice) {
                        agreement_copy = agreement_copy.replace(
                            /{subscriptionPrice}/g,
                            this.internationalizedPrice,
                        );
                    }
                    if (this.internationalizedPriceWithUnit) {
                        agreement_copy = agreement_copy.replace(
                            /{subscriptionPriceWithUnit}/g,
                            this.internationalizedPriceWithUnit,
                        );
                    }

                    agreement_copy = agreement_copy.replace(
                        /{subscriptionPeriod}/g,
                        localizedSubPeriod,
                    );
                    agreement_copy = agreement_copy.replace(/{(\w)*}/g, '');
                }

                return agreement_copy;
            }
        },
        updatePrice(e) {
            this.internationalizedPrice = e.internationalizedPrice;
            this.internationalizedPriceWithUnit = e.internationalizedPriceWithUnit;
            this.getNextNonZeroBillingPrice();
        },
        setupPricingHandlers() {
            if (this.recurly) {
                const currentPlan = this.plan;
                let pricing = this.recurly.Pricing();

                pricing.attach(this.$refs.paymentForm);

                if (!this.isEditPayment) {
                    let _self = this;
                    const { currencySymbol, currencyCode } = this.recurly.Pricing.Subscription();
                    pricing.on('change', () => {
                        // adding delay to ensure price change is applied before formatting
                        this.$nextTick(() => {
                            _self.updateSubTotal(currencySymbol, currencyCode);
                        });
                    });

                    // adding listener for annual plan upsell
                    pricing.on('set.plan', () => {
                        this.coupon.errorMessage = '';
                        this.updateSubTotal(currencySymbol, currencyCode);
                    });
                    _self.updateSubTotal(currencySymbol, currencyCode);
                    pricing.on('set.coupon', (coupon) => {
                        const { code } = coupon;
                        if (code) {
                            const split = code.split('-');
                            if (split.length >= 2 && split[split.length - 1].length === 2) {
                                coupon.regionCode = split.pop().toLowerCase();
                            }
                        }

                        // const exSubscriber = this.$root.serverData.user.isExSubscriber;
                        const exSubscriber = this.$store.state.serverData.user.isExSubscriber;

                        const checkError = this.checkForCouponErrors(
                            coupon,
                            this.registrationCountry
                                ? this.registrationCountry.toUpperCase()
                                : this.registrationCountry,
                            exSubscriber && Domain.isInternational(),
                        );

                        if (checkError) {
                            if (code.toLowerCase() !== this?.appliedCouponCode?.toLowerCase()) {
                                this.displayCouponErrorMessage(this.couponError(checkError));
                            } else {
                                this.orderSummaryConfig.inputList.couponCode.value = '';
                                this.$store.commit('setAutoPromoChecked', true);
                            }
                        } else {
                            let couponInfo = {
                                recurlyInfo: coupon,
                            };

                            this.orderSummaryConfig.inputList.couponCode.value = coupon.code;
                            if (code.toLowerCase() === this?.appliedCouponCode?.toLowerCase()) {
                                this.$store.commit('setAutoPromoChecked', true);
                            }

                            if (coupon.discount.type === 'free_trial') {
                                couponInfo.trialString = this.formatCouponString(
                                    coupon,
                                    currencySymbol,
                                    currencyCode,
                                    this.plan?.planType,
                                );
                            } else {
                                couponInfo.discountString = this.formatCouponString(
                                    coupon,
                                    currencySymbol,
                                    currencyCode,
                                    this.plan?.planType,
                                );
                            }
                            this.coupon = couponInfo;
                        }

                        // update sub total (for annual plan upsell when there"s a coupon)
                        this.formatOrderSummaryTotals();
                    });

                    pricing.on('error.coupon', (err) => {
                        if (
                            !this.submittedCouponCode ||
                            this.submittedCouponCode !== this.$store.state.promo
                        ) {
                            this.displayCouponErrorMessage(this.couponError(err));
                        } else {
                            this.coupon = {};
                            this.orderSummaryConfig.inputList.couponCode.value = '';
                            this.$store.commit('setAutoPromoChecked', true);
                        }
                    });
                }

                this.pricing = pricing;

                // Apply coupon
                let { promo } = this.$store.state;

                if (promo) {
                    this.applyCoupon({
                        plan: currentPlan.recurlyCode,
                        couponCode: promo,
                    });
                }
            }
        },
        applyCoupon(event) {
            let { recurlyInfo } = this.coupon;

            if (!recurlyInfo || recurlyInfo.code !== event.couponCode) {
                this.submittedCouponCode = event.couponCode;
                this.pricing
                    .plan(event.plan)
                    .coupon(event.couponCode)
                    .catch(() => {})
                    .done((price) => {
                        this.formatOrderSummaryTotals();
                    });
            }
        },
        updateOrder(order) {
            if (this.recurly) {
                this.appliedCouponCode = order.couponCode;
                this.coupon = {};
                this.couponDisclaimerCopy = '';

                // allow subtotal in view to be updated when plan changes from annual upsell toggle
                this.pricing.attach(this.$refs.paymentForm);
                this.pricing.on('set.plan', (plan) => {
                    // allow delay for price to change before formatting is applied
                    this.$nextTick(() => {
                        this.formatOrderSummaryTotals();
                    });
                });

                this.pricing
                    .plan(order.plan)
                    .coupon(order.couponCode)
                    .catch((err) => {})
                    .done((price) => {});
            }
        },
        resetCoupon(event) {
            this.coupon = {};
        },
        updateTrialEndDate(trialEndDateFormatted) {
            this.trialEndDateFormatted = trialEndDateFormatted;
        },
        mapRecurlyErrorFields(recurlyFieldsArr) {
            let errorMap = {};

            recurlyFieldsArr.forEach((dataRecurly) => {
                const fieldKey = this.dataRecurlyFieldKeyMap[dataRecurly];
                const field = this.fieldConfigs[fieldKey];
                errorMap[fieldKey] = this.$getLocale(`invalid_${fieldKey}`);
            });

            this.setFieldErrors(errorMap);
            this.formIsValid = false;
        },
        clearNotificationMessage() {
            if (
                this.$store.state.notification.isOpen &&
                !this.$store.state.notification.isClosing
            ) {
                this.$store.dispatch('notification/close');
            }
        },
        onClickSubmit(e) {
            if (this.formIsProcessing) {
                return false;
            }

            if (this.activeTab === this.tab.cc) {
                if (this.needsWaiveOfCoolingRights && !this.waiveOfCoolingChecked) {
                    this.setWaiveOfCoolingRightsErrorMessage();
                    // This if statement is intended for reuse validationForm method
                    // already available inside the handleSubmit function without
                    // duplicating the call to the validationForm method
                    if (!this.formIsValid) {
                        this.handleSubmit(e);
                    }
                } else {
                    this.handleSubmit(e);
                }
            } else {
                if (!this.payPalToken) {
                    return false;
                }

                if (this.needsWaiveOfCoolingRights && !this.waiveOfCoolingChecked) {
                    this.setWaiveOfCoolingRightsErrorMessage();
                } else {
                    if (this.isEditPayment) {
                        this.submitEditPayment(this.payPalToken);
                    } else {
                        this.submitPayment(this.payPalToken, e);
                    }
                }
            }
        },
        handleValidForm(e) {
            // Submit with click coordinates only
            if (!(e instanceof Event)) {
                return false;
            }

            if (this.activeTab === this.tab.cc) {
                this.formIsProcessing = true;

                let self = this;

                this.retrieveRecurlyToken(this.$refs.paymentForm)
                    .then((token) => {
                        if (token.id) {
                            if (this.isEditPayment) {
                                this.submitEditPayment(token);
                            } else {
                                this.submitPayment(token, e);
                            }
                        } else {
                            // TODO: display payment er
                        }
                    })
                    .catch((rejectVal) => {
                        this.formIsProcessing = false;
                        try {
                            let recurlyErrorFields = rejectVal.error.fields;

                            if (recurlyErrorFields.length > 0) {
                                this.mapRecurlyErrorFields(recurlyErrorFields);
                                this.scrollToFirstFieldError();
                            }
                        } catch (error) {}
                    });
            }
        },
        handlePayPalCheckout(e, paypalGateway = false) {
            e.preventDefault();
            if (!this.isEditPayment) {
                tracking.trackAction(ACTION_NAMES.PAYPAL_CHECKOUT_BUTTON, {
                    ctaText: 'checkout paypal',
                });
            } else {
                this.$trackingService.trackAction(ACTION_NAMES.SWITCH_TO_PAYPAL, {
                    pageType: 'account_switchpayment',
                    payPalCheckoutClick: '1',
                });
            }

            let self = this;

            this.retrieveRecurlyPayPalToken(paypalGateway)
                .then((token) => {
                    self.payPalToken = token;
                    this.isEditPayment && self.onClickSubmit();
                })
                .catch((rejectVal) => {});
        },
        resubmitPayment(token) {
            const payload = {
                ...this.paymentRequestData,
                resultActionTokenId: token.id,
            };
            const httpData = this.isEditPayment ? this.buildHttpData(payload) : payload;
            this.sendPayment(httpData);
        },
        setUpThreeDSecure(actionTokenId) {
            if (actionTokenId) {
                const risk = this.recurly.Risk();

                const threeDSecure = risk.ThreeDSecure({ actionTokenId });
                threeDSecure.on('error', (err) => {
                    this.formErrorMessage = this.$getLocale('recurly_PSD2_error_please_try_again');
                    this.closeThreeDModal();
                    threeDSecure.remove();
                });
                threeDSecure.on('token', (token) => {
                    this.resubmitPayment(token);
                    this.closeThreeDModal();
                    threeDSecure.remove();
                });
                this.openThreeDModal();
                threeDSecure.attach(this.$refs.threeDSecure);
            }
        },
        submitEditPayment(token) {
            const payload = {
                token: token.id,
            };

            // START TEST PLOCTOPLUS-1298: google captcha
            if (this.formData?.recaptchaToken) {
                payload.recaptchaToken = this.formData.recaptchaToken;
            }
            // END TEST PLOCTOPLUS-1298: google captcha

            this.paymentRequestData = payload;
            const httpData = this.buildHttpData(payload);
            this.sendPayment(httpData);
        },

        submitPayment(token, buttonEvent) {
            this.formErrorMessage = null;
            this.formIsProcessing = true;

            let couponCode = null;
            if (this.coupon && this.coupon.recurlyInfo && this.coupon.recurlyInfo.code) {
                couponCode = this.coupon.recurlyInfo.code;
            }

            const payload = {
                token: token,
                buttonEvent: buttonEvent,
                plan: this.plan,
                couponCode: couponCode,
                tk_trp: this.$store.state.token,
            };

            // START TEST PLOCTOPLUS-1298: google captcha
            if (this.formData?.recaptchaToken) {
                payload.recaptchaToken = this.formData.recaptchaToken;
            }
            // END TEST PLOCTOPLUS-1298: google captcha

            // Save payload for retry if needed
            this.paymentRequestData = payload;

            this.isEditPayment && tracking.trackAction(ACTION_NAMES.CREDIT_CARD_SUBMIT_UPDATE);
            this.sendPayment(payload);
        },
        sendPayment(payload) {
            this.formIsProcessing = true;

            const couponCode = payload.couponCode;
            if ('URLSearchParams' in window) {
                if (location.search) {
                    const searchParams = new URLSearchParams(location.search.substring(1));
                    const gatewayOverride = searchParams.get('gateway');
                    if (gatewayOverride) {
                        payload.gateway = gatewayOverride;
                    }
                }
            }
            const processSelectedMethod = this.isEditPayment
                ? this.processEditPayment
                : this.processPayment;
            processSelectedMethod(payload)
                .then((resp) => {
                    // START TEST PLOCTOPLUS-1298: google captcha
                    this.trackCaptchaTokenValidationResult(resp);
                    // END TEST PLOCTOPLUS-1298: google captcha
                    this.handleProcessedPayment(resp);
                    if (this.isEditPayment) {
                        this.$trackingService.trackAction(ACTION_NAMES.UPDATE_PAYPAL_SUCCESS, {
                            pageType: 'account_switchpayment',
                        });
                    }
                })
                .catch((err) => {
                    // START TEST PLOCTOPLUS-1298: google captcha
                    this.trackCaptchaTokenValidationResult(err);
                    // END TEST PLOCTOPLUS-1298: google captcha

                    this.handleProcessedPaymentError(err);
                })
                .finally(() => {
                    this.formIsProcessing = false;
                });
        },
        setWaiveOfCoolingRights(e) {
            this.waiveOfCoolingChecked = e.value;
            if (this.waiveOfCoolingRighsCheckboxErrorMessage) {
                this.waiveOfCoolingRighsCheckboxErrorMessage = '';
            }
        },
        setWaiveOfCoolingRightsErrorMessage() {
            this.waiveOfCoolingRighsCheckboxErrorMessage =
                this.pageAttr?.['waive_of_cooling_rights_error'];
        },
        pollForPaymentRef() {
            // https://jefrydco.id/en/blog/safe-access-vue-refs-undefined#safeway
            const interval = setInterval(() => {
                if (this.$refs.paymentForm) {
                    this.recurlyWrappersLoaded = true;
                    if (this.recurly) {
                        this.configureRecurly();
                    }
                    clearInterval(interval);
                }
            }, 50);
        },

        addTrialPeriod() {
            if (this.plan.trial) {
                return getEndDate(this.plan.trialInterval, this.plan.trialUnit);
            }
            return new Date();
        },

        calcFullCouponBillingDate(coupon, couponLength, unit) {
            const purchaseDate = this.addTrialPeriod();
            const isSingleUseCoupon = this.isSwitchPlan
                ? coupon?.singleUse
                : coupon?.recurlyInfo?.single_use;
            const isPlanTypeAnnual = this.plan.planType === Product.TYPE_ANNUAL;
            const planUnit = this.plan.planType === Product.TYPE_MONTHLY ? 'month' : 'year';

            // Coupon lengths for annual plan are suppose to be 12 months, 24 months and so on...
            const isAnnualTypeCoupon = couponLength % 12 === 0;

            let billingDate = null;
            if (!isSingleUseCoupon && isPlanTypeAnnual && !isAnnualTypeCoupon) {
                billingDate = purchaseDate;
            } else {
                // Single use coupon adds extra month or year
                if (isSingleUseCoupon) {
                    couponLength = 1;
                    unit = planUnit;
                }
                billingDate = addCouponDiscountPeriod(purchaseDate, couponLength, unit);
            }
            this.couponDiscountBillingDate = DateFormatter.format(
                billingDate,
                null,
                CBS.Registry.region.dateLocale,
            );
        },
    },
    watch: {
        recurly(newVal, oldVal) {
            if (newVal && this.recurlyWrappersLoaded) {
                this.configureRecurly();
            }
        },
    },
    created() {
        this.registrationCountry = this.$store.state.serverData.paymentForm?.country?.value
            ? this.$store.state.serverData.paymentForm?.country?.value.toLowerCase()
            : false;
        this.threeDSecureEnabled = this.$store.state.serverData.threeDSecure;
        this.featureDynamicAgreementCopy =
            this.$store.getters.featureIsActive(AA_DYNAMIC_AGREEMENT_COPY);
        this.enhancedPaymentSummaryEnabled =
            this.$store.state.serverData.enhancedPaymentSummaryEnabled;
        this.waiveOfCoolingRightsEnabled = this.featureIsActive(WAIVE_COOLING_RIGHTS_ENABLED);

        CreditCardPaymentConfig.recurlyConfig.publicKey =
            this.$store.state.serverData.recurlyPublicKey;
    },
    mounted() {
        // add check for language
        PaymentService.setHttpService(this.$store.$http);
        this.pollForPaymentRef();
    },
    beforeDestroy() {
        // this condition is to prevent the notification from being closed
        // just after the edit payment form collapse in the summary page
        if (
            typeof this.isFromPaymentSummary === 'undefined' ||
            this.isFromPaymentSummary === false
        ) {
            this.clearNotificationMessage();
        }
    },
};
