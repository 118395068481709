import Step from 'models/steps/Step';
import Notification from 'aa/vue/models/NotificationBarModel';
import Product from 'aa/vue/models/Product';

export default class SuperFunnel extends Step {
    path = '/skip-to/:step/:preset/:partner/';

    setPlanDataForRouteSkip(preset) {
        let planType = preset.indexOf('annual-') === 0 ? Product.TYPE_ANNUAL : Product.TYPE_MONTHLY;
        let package_code =
            preset.indexOf('-cf') > 0 ? Product.PACKAGE_CODE.CF : Product.PACKAGE_CODE.LCP;

        this._store.commit('plan/setPlanType', planType);
        this._store.dispatch('plan/updateSelectedPlanWithTypeandPackage', {
            planType,
            package_code,
        });
    }

    skipStepWelcomeNotification(partner = null, redirectName) {
        if (!partner) {
            return;
        }

        this._store
            .dispatch('pageAttributes/loadSkipStepNotification', {
                partnerCode: partner,
            })
            .then((pageAttributes) => {
                let skipStepNotification = new Notification({
                    messageTitle: pageAttributes.notificationTitle,
                    message: pageAttributes.notificationMessage,
                    showBelowHeader: true,
                    routeNameForMessage: redirectName,
                    autoClose: true,
                    success: true,
                });

                this._store.dispatch('notification/open', skipStepNotification);
                this._store.dispatch('notification/showOnlyOnXNumPages');
            })
            .catch((err) => {
                console.error(err);
            });
    }

    redirect(to) {
        let redirectUrl, redirectName;

        switch (to.params.step) {
            case 'pickplan':
                redirectName = 'PLAN';
                redirectUrl = '/signup/pickplan/';
                break;
            case 'account':
                redirectName = 'sign_up_explainer';
                redirectUrl = '/signup/account/';
                break;
            case 'create-account':
                redirectName = 'SIGNUP';
                redirectUrl = '/signup/createaccount/';
                break;
        }

        this.setPlanDataForRouteSkip(to.params.preset);
        this.skipStepWelcomeNotification(to.params.partner, redirectName);

        return redirectUrl;
    }

    getRoute() {
        return {
            path: this.path,
            redirect: this.redirect.bind(this),
        };
    }
}
