export default {
    data() {
        return {
            authorizationIsProcessing: false,
        };
    },
    methods: {
        authorize() {
            if (this.authorizationIsProcessing) {
                return false;
            }

            this.authorizationIsProcessing = true;

            let httpData = {
                client_id: this.$route.query.client_id,
                redirect_uri: this.$route.query.redirect_uri,
                response_type: this.$route.query.response_type,
                scope: this.$route.query.scope,
                state: this.$route.query.state,
                tk_trp: this.$store.state.token,
            };

            this.$store.$http
                .doPost('/oauth/authorize/', httpData)
                .then((res) => {
                    if (res.success) {
                        window.location.href = res.data.destination_uri;
                    } else {
                        this.onAuthorizationError();
                    }
                })
                .catch((err) => {
                    this.onAuthorizationError();
                })
                .finally(() => {
                    this.authorizationIsProcessing = false;
                });
        },
    },
};
