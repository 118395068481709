<template>
    <div
        class="payment-summary payment-summary__card-gap"
        :class="countryClass"
        data-ci="payment-summary__promo">
        <template v-if="displayTrialInfo">
            <div class="payment-summary__two-col" data-ci="payment-summary__trialString">
                <div class="col">
                    {{ pageAttr.billing_summary_trial_label }}
                </div>
                <div class="col qt-trial-price">
                    {{ coupon.trialString || (plan && plan.trialString) }}
                </div>
            </div>
        </template>
        <template v-if="coupon.discountString">
            <div
                :class="[
                    'payment-summary__two-col',
                    displayTrialInfo ? 'payment-summary__single-col-gap' : '',
                ]"
                data-ci="payment-summary__discountString">
                <div class="col">{{ $getLocale('discount') }}</div>
                <div class="col qt-discount-price">
                    {{ coupon.discountString }}
                </div>
            </div>
            <div class="payment-summary__details-text payment-summary__single-col-gap-details">
                <div class="payment-summary__text-strikethrough">{{ internationalizedPrice }}</div>
            </div>
            <div class="payment-summary__details-text">
                <div data-testid="subtotalText" v-show="showSubTotalNow">
                    {{ this.formatSubTotalText }}
                </div>
            </div>
        </template>
        <template v-if="pageAttr.billing_summary_minimum_term_enabled">
            <div
                data-testid="showMinimumTerm"
                :class="[
                    'payment-summary__two-col',
                    coupon.discountString || displayTrialInfo
                        ? 'payment-summary__single-col-large-gap'
                        : '',
                ]">
                <div class="col">
                    {{ pageAttr.billing_summary_minimum_term }}
                </div>
                <div data-testid="minTermValue" class="col">
                    {{
                        plan && plan.planType === planTypeMonthly
                            ? pageAttr.billing_summary_minimum_term_monthly
                            : pageAttr.billing_summary_minimum_term_annual
                    }}
                </div>
            </div>
            <div
                data-testid="showMinimumTermAutoRenewal"
                class="payment-summary__details-text payment-summary__single-col-gap-details">
                {{ pageAttr.billing_summary_minimum_term_auto_renewal }}
            </div>
        </template>
        <template
            v-if="
                isPaymentPage &&
                (this.$route.name === 'PAYMENT_SUMMARY' || this.$route.name === 'DIRECTLINK')
            ">
            <div class="payment-summary__section-border" v-if="displayBorderLine"></div>
            <button
                class="payment-summary__coupon-prompt"
                :aria-expanded="couponFieldOpen ? true : false"
                @click="handleClickCouponPrompt">
                <div class="prompt qt-promo-link" tabindex="0">
                    {{ pageAttr.billing_summary_coupon_field_prompt }}
                </div>
                <div :class="iconCaretClassList"></div>
            </button>

            <transition name="slide-down-fade">
                <div v-if="!promo || autoPromoChecked" v-show="couponFieldOpen">
                    <div class="payment-summary__coupon">
                        <InputField
                            :inputConfig="orderSummaryConfig.inputList.couponCode"
                            v-model="form['couponCode']"
                            :class="{ error: coupon.errorMessage }"
                            :keydownHandler="keydownHandler"></InputField>
                        <InputElm
                            v-show="displayPromoField"
                            ref="planRecurly"
                            :inputConfig="orderSummaryConfig.hiddenInputList.plan" />

                        <div class="payment-summary__coupon__error qt-err-coupon-code">
                            {{ coupon.errorMessage }}
                        </div>
                        <ButtonCta :buttonConfig="buttonConfig"></ButtonCta>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
    import InputElm from 'atoms/InputElm';
    import InputField from 'atoms/InputField';
    import ButtonCta from 'atoms/ButtonCta';
    import { mapState } from 'vuex';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import { capitalize } from 'aa/vue/components/account/helpers';
    import { pluralizeDuration } from 'aa/helpers/stringManipulation';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'BillingPromoSummary',
        mixins: [pricePrettyPrint],
        components: {
            InputElm,
            InputField,
            ButtonCta,
        },

        props: {
            formConfig: {
                type: Object,
                default: null,
            },
            displayTrialInfo: {
                type: String,
            },
            orderSummaryConfig: {
                type: Object,
                default: null,
            },
            coupon: {
                type: Object,
            },
            keydownHandler: {
                type: Function,
            },
            buttonConfig: {
                type: Object,
            },
            plan: {
                type: Object,
            },
            form: {
                type: Object,
            },
            handleClickCouponCTA: {
                type: Function,
            },
            countryClass: {
                type: String,
            },
            internationalizedPrice: {
                type: String,
            },
            showSubTotalNow: {
                type: Boolean,
            },
            showSubTotalNext: {
                type: Boolean,
            },
            showIntlZeroPrice: {
                type: Boolean,
            },
            pricing: {
                type: Object,
            },
            pageAttr: {
                type: Object,
            },
        },

        data: function () {
            return {
                isPaymentPage: true,
                couponFieldManualOpen: null,
                planTypeMonthly: Product.TYPE_MONTHLY,
            };
        },

        methods: {
            handleClickCouponPrompt(e) {
                e.preventDefault();

                this.couponFieldOpen = !this.couponFieldOpen;
            },
            getLocalizedTemporalUnitFromPlan() {
                //This method assumes it has the localized cadence unit from Product.php see getPriceUnit
                let localizedPlanTemporalUnit = this.plan?.priceUnit;
                if (this.isFirstLetterUpperCaseForMonthDayYear) {
                    localizedPlanTemporalUnit = capitalize(this.plan?.priceUnit);
                } else {
                    localizedPlanTemporalUnit = this.plan?.priceUnit?.toLowerCase();
                }
                return localizedPlanTemporalUnit;
            },
            replaceDurationText(subTotal, temporalAmount, temporalUnit, term) {
                let subTotalAndDuration = '';

                if (this.pageAttr.billing_summary_promotion_duration) {
                    subTotalAndDuration = this.pageAttr.billing_summary_promotion_duration;
                    subTotalAndDuration = subTotalAndDuration.replace(/{subTotal}/g, subTotal);
                    subTotalAndDuration = subTotalAndDuration.replace(
                        /{temporalAmount}/g,
                        temporalAmount,
                    );

                    const localizedPlanTemporalUnit = this.getLocalizedTemporalUnitFromPlan();
                    const singularLocalizedTemporalUnit = this.$getLocale(temporalUnit);
                    const pluralLocalizedTemporalUnit = this.$getLocale(temporalUnit + 's');

                    //pluralizeDuration conditionally adds an 's' to the end of the temporalUnit if the temporalAmount is greater than 1
                    const dynamicLocalizedTemporalUnit = this.$getLocale(
                        pluralizeDuration(temporalAmount, temporalUnit),
                    );

                    subTotalAndDuration = subTotalAndDuration.replace(
                        /{subscriptionPeriod}/g,
                        localizedPlanTemporalUnit,
                    );

                    subTotalAndDuration = subTotalAndDuration.replace(
                        /{temporalUnitSingular}/g,
                        singularLocalizedTemporalUnit,
                    );

                    subTotalAndDuration = subTotalAndDuration.replace(
                        /{temporalUnitPlural}/g,
                        pluralLocalizedTemporalUnit,
                    );

                    subTotalAndDuration = subTotalAndDuration.replace(
                        /{temporalUnit}/g,
                        dynamicLocalizedTemporalUnit,
                    );

                    subTotalAndDuration = subTotalAndDuration.replace(
                        /{discountDuration}/g,
                        `${temporalAmount} ${dynamicLocalizedTemporalUnit}`,
                    );

                    subTotalAndDuration = subTotalAndDuration.replace(/{term}/g, term);
                }

                return subTotalAndDuration;
            },
        },

        computed: {
            ...mapState(['autoPromoChecked']),

            ...mapState(['promo']),

            couponFieldOpen: {
                get() {
                    const defaultVal =
                        this.$store.state.promo && this.formConfig.inputList.couponCode.value;
                    return typeof this.couponFieldManualOpen == 'boolean'
                        ? this.couponFieldManualOpen
                        : defaultVal;
                },
                set(newVal) {
                    this.couponFieldManualOpen = newVal;
                },
            },

            iconCaretClassList() {
                return ['icon', 'icon--caret', this.couponFieldOpen ? 'icon--caret--up' : ''];
            },

            displayPromoField() {
                return this.promo && this.orderSummaryConfig.inputList.couponCode.value;
            },

            formatSubTotalText() {
                const { temporal_amount, temporal_unit, single_use, duration } =
                    this.coupon.recurlyInfo;
                let totalPrice = this.displayTrialInfo
                    ? this.pricing.price.next.total
                    : this.pricing.price.now.total;
                const subTotal = this.pricePrettyPrint(
                    totalPrice,
                    this.pricing.currencySymbol,
                    this.pricing.currencyCode,
                );

                let subTotalAndDuration;

                if (duration === 'forever') {
                    subTotalAndDuration = this.replaceDurationText(
                        subTotal,
                        this.$getLocale('forever'),
                        '',
                        '',
                    );
                } else if (temporal_unit && temporal_amount && single_use === false) {
                    if (this.plan.planType === Product.TYPE_ANNUAL && temporal_unit !== 'year') {
                        subTotalAndDuration = this.replaceDurationText(
                            subTotal,
                            temporal_unit === 'month'
                                ? Math.ceil(parseInt(temporal_amount) / 12)
                                : 1,
                            'year',
                            this.pageAttr.promotion_term,
                        );
                    } else {
                        subTotalAndDuration = this.replaceDurationText(
                            subTotal,
                            temporal_amount,
                            temporal_unit,
                            this.pageAttr.promotion_term,
                        );
                    }
                } else {
                    subTotalAndDuration = this.replaceDurationText(
                        subTotal,
                        1,
                        this.plan.planType === Product.TYPE_ANNUAL ? 'year' : 'month',
                        this.pageAttr.promotion_term,
                    );
                }

                if (!!subTotalAndDuration) {
                    return `${subTotalAndDuration} ${this.pageAttr.billing_summary_promotion_condition}`;
                } else {
                    return '';
                }
            },
            displayBorderLine() {
                return (
                    this.displayTrialInfo ||
                    this.coupon.discountString ||
                    this.pageAttr.billing_summary_minimum_term_enabled
                );
            },
            isFirstLetterUpperCaseForMonthDayYear() {
                return this.$store?.state?.serverData?.isFirstLetterUpperCaseForMonthDayYear;
            },
        },
        watch: {
            coupon(newVal) {
                if (newVal?.discountString || newVal?.trialString) {
                    this.couponFieldOpen = false;
                }
            },
        },
    };
</script>
