import TierComponent from 'aa/vue/components/multisubplanpicker/Tier';
import Step from 'models/steps/Step';
import { addClassToSelector, removeClassFromSelector } from 'aa/helpers/styleAdjuster';

export default class Tier extends Step {
    path = '/account/signup/tier/';
    name = 'tier';
    component = TierComponent;
    props = true;
    requireAuth = true;
    requireNonAuth = false;
    forceNoPageAttrsNeedUpdate = true;
    countStep = true;

    async beforeEnter(to, from, next) {
        addClassToSelector('#main-container', 'msp');

        if (!this._store.getters['plan/skipTier']) {
            this.getPromo();
            window.sessionStorage.removeItem('switchToAnnual');
            this._store.dispatch('plan/setSwitchToAnnual', false);
        }

        const { stepsHeader, stepsCounter } = this._store.getters['plan/getDefaultHeadings'];
        to.params.stepsHeader = stepsHeader;
        to.params.stepsCounter = stepsCounter;

        return super.beforeEnter(to, from, next);
    }

    beforeRouteLeave(to, from, next) {
        removeClassFromSelector('#main-container', 'msp');
    }

    /**
     * @param from
     * @returns {Promise<[unknown, unknown]>}
     */
    checkAccess(from) {
        return Promise.all([super.checkAccess(from), this._store.dispatch('plan/getPlans')]);
    }

    checkFlowRedirect(from) {
        if (this._store.getters['plan/skipTier']) {
            return { name: 'cadence' };
        }
        if (
            !this._store.state.serverData.annualPlanOption &&
            !this._store.state.serverData.multiSubPlanPicker
        ) {
            if (!this._store.state.user.isLoggedIn) {
                return this._store.getters['flow/isGift']
                    ? { name: 'SIGNUP' }
                    : { name: 'sign_up_explainer' };
            } else {
                return this._store.getters['flow/isGift']
                    ? { name: this._store.getters['payment/paymentRoute'] }
                    : {
                          name: 'payment_explainer',
                      };
            }
        }

        return super.checkFlowRedirect(from);
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageName = window.location.pathname;
        args.pageType = 'svod_pickaplan';

        args.userType = this._store.state.user.svod.status;
        args.userRegId = this._store.state.user.regID;

        return args;
    }
}
