<template>
    <ul class="feature-list" data-ci="feature-list">
        <li
            v-for="feature in features"
            :class="feature.class"
            :data-ci="feature.dataCi || feature.class">
            <span v-if="feature.rawHtml" v-html="feature.description"></span>
            <span v-else>{{ feature.description }}</span>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'FeatureList',
        props: {
            features: {
                type: Array,
                required: true,
            },
        },
    };
</script>
