import Step from 'models/steps/Step';
import CatanBundleComponent from 'aa/vue/components/CatanBundle';
import Product from 'aa/vue/models/Product';
import { PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';

export default class PartnerBundlePlan extends Step {
    path = '/account/signup/partner-bundle/';
    name = 'CATAN_BUNDLE';
    component = CatanBundleComponent;

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        args.pageType = PAGE_TYPES.BUNDLE_INTERSTITIAL;
        args.partnerBundle = Product.ADDON_PARTNER_BUNDLE;

        return args;
    }

    async beforeEnter(to, from, next) {
        if (this.skipStep()) {
            if (this._store.getters['user/isLoggedIn']) {
                next({ name: this._store.getters['payment/paymentRoute'] });
            } else {
                next({ name: 'SIGNUP' });
            }
        } else {
            await super.beforeEnter(to, from, next);
        }
    }

    skipStep() {
        return this._store.getters['pageAttributes/hideBundlePlanCTAs'];
    }
}
