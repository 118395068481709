<template>
    <div class="payment-summary payment-summary__card-gap" :class="countryClass">
        <h4 class="payment-summary__your-bill-header" data-ci="payment-summary__your-bill-header">
            {{ pageAttr.billing_summary_your_bill_header }}
        </h4>

        <div class="payment-summary__two-col">
            <div data-testid="first-payment" class="payment-summary__first-payment-gap">
                {{ pageAttr.billing_summary_first_payment_label }}
            </div>
            <div class="payment-summary__first-payment-gap">
                {{ nextBillingDate }}
            </div>

            <div class="col">
                {{ pageAttr.billing_summary_total_label }}
            </div>
            <div class="col">
                <span class="next-nonzero-billing-price" data-ci="next-nonzero-billing-price">
                    {{ nextNonZeroBillingPrice }}
                </span>
            </div>
        </div>
        <div
            data-testid="totalDisclaimer"
            class="payment-summary__first-payment-gap payment-summary__details-text payment-summary__single-col-gap-details">
            {{ pageAttr.billing_summary_tax_disclaimer }}
        </div>
        <div class="payment-summary__section-border" v-if="disclaimerCopyEl"></div>
        <div class="payment-summary__disclaimer" v-html="disclaimerCopyEl"></div>
    </div>
</template>

<script>
    export default {
        name: 'BillingPaymentSummary',

        props: {
            countryClass: {
                type: String,
            },
            displayTrialInfo: {
                type: String,
            },
            disclaimerCopyEl: {
                type: String,
            },
            nextBillingDate: {
                type: String,
            },
            pageAttr: {
                type: Object,
            },
            discountString: {
                type: String,
            },
            nextNonZeroBillingPrice: {
                type: String,
            },
        },
    };
</script>
