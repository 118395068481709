export default {
    computed: {},
    methods: {
        displayPriceBeforeDiscount(plan) {
            return plan.couponDiscount?.isPriceDiscount && this.priceAfterDiscount(plan);
        },

        priceAfterDiscount(plan) {
            if (!plan.couponDiscount?.isPriceDiscount) {
                return null;
            }

            let { currencyCode, priceSubtotal } = plan.couponDiscount;

            return this.priceFormat(priceSubtotal, currencyCode, plan.priceUnit);
        },

        priceBeforeDiscount(plan) {
            if (!this.displayPriceBeforeDiscount(plan)) {
                return null;
            }

            return this.priceFormat(plan.rawPrice, plan.currency);
        },

        priceCopy(plan) {
            return (
                this.priceAfterDiscount(plan) ||
                this.priceFormat(plan.rawPrice, plan.currency, plan.priceUnit)
            );
        },

        /**
         * Returns a formatted discounted price string.
         *
         * @example $1 off per month returns $1.00/month
         *
         * @param {Object} plan - The plan object containing the coupon discount and currency information.
         * @returns {string} - The formatted price discount.
         */
        priceDiscountCopy(plan) {
            let priceDiscount = plan.couponDiscount.priceDiscount;
            let currency = plan.currency;
            let durationUnit = plan.couponDiscount.duration.unit;

            return this.priceFormat(priceDiscount, currency, durationUnit);
        },

        priceFormat(rawPrice, currencyCode, durationUnit) {
            const price = Intl.NumberFormat(this.locale, {
                style: 'currency',
                currency: currencyCode,
                currencyDisplay: 'narrowSymbol',
            }).format(rawPrice);

            const currency = this.displayCurrencyCode ? ' ' + currencyCode : '';
            const unit = durationUnit
                ? `/${this.$getLocale(durationUnit).toLocaleLowerCase(this.locale)}`
                : '';

            return `${price}${currency}${unit}`;
        },
    },
};
