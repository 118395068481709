import UserMessage from 'aa/vue/components/UserMessage';
import { getLocale } from 'services/LocalizationService';
import { tracking, ACTION_NAMES } from 'services/Tracking';

export default class GenericError {
    props = {
        primaryButton: {
            label: getLocale('close'),
            link: '/account',
        },
    };
    beforeEnter = (to, from, next) => {
        this.props.heading =
            getLocale(to.params.heading, to.params.headingLocaleParams || {}) ||
            getLocale('dl_error_tech_error');
        this.props.subHeading =
            to.params.subHeading.map((subHeading) => getLocale(subHeading)).join('\n') || '';
        if (to.params.primaryButton) {
            this.props.primaryButton = { ...this.props.primaryButton, ...to.params.primaryButton };
        }
        if (to.params.errorTrackingPageType) {
            tracking.trackState({
                pageType: to.params.errorTrackingPageType,
                pageUrl: window.location.href,
            });
            tracking.trackAction(ACTION_NAMES.APP_LOG, {
                pageType: to.params.errorTrackingPageType,
                pageUrl: window.location.href,
                appLogText: to.params.subHeading.join('+'),
                appLogType: to.params.heading,
                appLogCode: 'No code provided',
                ...to.params.errorTrackingProps,
            });
        }
        next();
    };
    path = '/error';
    name = 'GenericError';
    component = UserMessage;
    requireAuth = false;
    requireNonAuth = false;
}
