import Step from 'models/steps/Step';
import AgeGateComponent from 'aa/vue/components/AgeGate';

export default class AgeAgate extends Step {
    path = '/account/signup/age-gate';
    name = 'AGE_GATE';
    component = AgeGateComponent;
    requireAuth = false;
    requireNonAuth = true;
    countStep = false;
    hideSignInLink = true;
    hideSignUpLink = true;

    async beforeEnter(to, from, next) {
        if (!from || from.name === null) {
            window.location.href = '/';
            return;
        }
        return super.beforeEnter(to, from, next);
    }
}
