<!-- START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
<template>
    <div :class="wrapperClasses" @click="planTileSelect">
        <div class="radio-wrapper">
            <div :class="radioBoxClassList"></div>
            <div class="plan-info">
                <div>
                    <span class="title">{{ plan.planType }}</span>
                    <span class="pill" v-if="isPlanAnnual">{{ pillText }}</span>
                </div>

                <div class="price">{{ price }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Product from 'aa/vue/models/Product';
    import { mapState } from 'vuex';
    export default {
        name: 'PlanTile',
        props: {
            price: {
                type: String,
                required: true,
                default: () => '',
            },
            plan: {
                type: Object,
                required: true,
                default: () => {},
            },
            idx: {
                type: Number,
                required: true,
                default: () => 0,
            },
            isActive: {
                type: Boolean,
                required: true,
                default: () => false,
            },
        },
        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            radioBoxClassList() {
                const classList = ['radio-box'];
                if (this.isActive) {
                    classList.push('selected');
                }
                return classList;
            },
            wrapperClasses() {
                const classList = ['plan-tiles-wrapper'];
                if (this.isActive) {
                    classList.push('active');
                }
                return classList;
            },
            isPlanAnnual() {
                return this.plan?.planType === Product.TYPE_ANNUAL;
            },
            pillText() {
                return this.pageAttr?.aa_payment_upgrade_annual_pill_text ?? '';
            },
        },
        methods: {
            planTileSelect(e) {
                this.$emit('planSelected', this.idx);
            },
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX -->
