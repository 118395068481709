import InterstitialStep from 'aa/vue/models/steps/InterstitialStep';
import { SWITCH_PLAN_ENABLED } from 'aa/helpers/featureConstants';
import { getLocale } from 'services/LocalizationService';
import Domain from 'helpers/Domain';

export default class SignUpExplainer extends InterstitialStep {
    requireAuth = false;
    requireNonAuth = true;
    allowGhost = true;

    path = '/account/signup/account';
    name = 'sign_up_explainer';
    nameForTracking = 'EXPLAINER_STEPS_' + (Domain.isDomestic() ? '2' : '1');
    props = {
        title: getLocale('youre_just_a_few_quick_steps_away_from_streaming'),
        description: getLocale('create_your_account'),
        messages: [
            getLocale('get_personalized_recommendations'),
            getLocale('resume_watching_where_you_left_off_on_any_supported_streaming_device'),
        ],
        // START TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
        stepName: this.name,
        // END TEST PLOCTOPLUS-2042 Intl Skip Explainer Steps
    };

    /**
     *
     * Redirect to interstitial 1 if no plan selected
     * This would occur on direct access / page refresh
     *
     * @param from
     * @returns {null|{name: string}}
     */
    checkFlowRedirect(from) {
        // START A/B TEST PLOCTOPLUS-1391 : Skip Explainer Steps
        if (this._store.getters['variant/isIntlRemoveExplainerSteps']) {
            return { name: 'SIGNUP' };
        }
        // END A/B TEST PLOCTOPLUS-1391 : Skip Explainer Steps
        if (
            Domain.isDomestic() &&
            this._store.getters.featureIsActive(SWITCH_PLAN_ENABLED) &&
            !this._store.getters['flow/skipPlan']
        ) {
            if (!this._store.getters['plan/selectedPlan']) {
                return {
                    name: 'plan_explainer',
                };
            }
        }

        return null;
    }

    /**
     * @param to
     * @returns {{path: *, name: *}}
     */
    getTrackingArgs(to) {
        let args = super.getTrackingArgs(to);

        let productParams = this._store.getters['getProductParamsForTracking'];

        args.pageTitle = `Explainer Page ${Domain.isDomestic() ? '2' : '1'}`;
        args.pageType = 'svod_signup_explainer';

        if (
            this._store.getters['flow/isRendezvous'] &&
            this._store.state.serverData.enhancedTrackActivationSuccess
        ) {
            args.activationDeviceFamily = this._store.state.serverData.flow.partner;
        }

        Object.assign(args, productParams);

        return args;
    }
}
