import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isCenterAlignPaymentPage: 'variant/isCenterAlignPaymentPage',
            isCenterAlignPaymentPageModulesAbove: 'variant/isCenterAlignPaymentPageModulesAbove',
        }),
        getPaymentFormTabsModuleClass() {
            return {
                'payment-layout__tab-content': true,
                'credit-card-paypal-module': this.isCenterAlignPaymentPageModulesAbove,
            };
        },
    },
};
